import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import tw from "twin.macro";
import { useQuery, gql, useMutation } from "@apollo/client";
import { format, formatISO, isDate, parseISO } from "date-fns";

import Confetti from "react-dom-confetti";
import styled from "@emotion/styled";

import { Heading } from "./Account";
import { SubmitButton } from "./Form";
import {iconMap} from './TransactionHistory';

import { ReactComponent as IconHeart} from "./icon-hand-heart.svg"
import { ReactComponent as IconCal} from "./icon-cal-color.svg"
import { ReactComponent as IconCredit} from "./icon-credit-card.svg"
import { ReactComponent as IconMoneyFolder} from "./icon-money-folder.svg"



/** @jsx jsx */
import { jsx } from "@emotion/core";
import Loading from "./Loading";
import { MoneyInput } from "./AdminCredit";
import PartnerAcordian from "./Partners";
import CreditPdf from "./credit-policy.pdf";
import { Link } from "react-router-dom";

export const CREDITS_INFO = gql`
  query($userId: String!) {
    transactions(where: { user_id: { _eq: $userId } }, limit: 3, order_by: {id: desc}) {
      created_at
      amount
      id
      type
    }
    transactions_aggregate(where: { user_id: { _eq: $userId } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
    time_sheets_aggregate(where: { user_id: { _eq: $userId } }) {
      aggregate {
        sum {
          duration_minutes
        }
      }
    }
    users(where: { id: { _eq: $userId } }) {
      transactions_aggregate(where: { type: { _eq: "donation" } }) {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`;

const INSERT_TRANSACTION = gql`
  mutation AddCredit($amount: money!, $userId: String!) {
    insert_transactions(
      objects: [{ amount: $amount, user_id: $userId, type: "donation" }]
    ) {
      returning {
        id
        amount
        type
        user_id
      }
    }
  }
`;

const config = {
  angle: 90,
  spread: 300,
  startVelocity: 40,
  elementCount: 100,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const Title = styled.div(tw`text-base`);
const ValueText = styled.div(tw`font-semibold text-lg`);

const TotalContainer = styled.div(tw`
flex items-center mb-6
`);

const percentages = [0.1, 0.25, 0.5, 1];
function Credit() {
  const { user } = useAuth0();
  const [showDonate, setShowDonate] = useState(false);
  const [customDollarAmount, setCustomDollarAmount] = useState(0);
  const [customCentsAmount, setCustomCentsAmount] = useState(0);
  const [donationError, setDonationError] = useState([false, ""]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [donationSelect, setDonationSelect] = useState(false);
  const [donationComplete, setDonationComplete] = useState(false);

  const { loading, error, data } = useQuery(CREDITS_INFO, {
    variables: {
      userId: user.sub,
    },
  });

  const [addTransaction] = useMutation(INSERT_TRANSACTION, {
    onCompleted: () => {
      setDonationComplete(true);
      setDisableSubmit(false);
      setShowDonate(false);
    },
    refetchQueries: [
      {
        query: CREDITS_INFO,
        variables: {
          userId: user.sub,
        },
      },
    ],
  });

  if (loading) return <Loading />;

  if (error) {
    console.log(error);
  }
  if (
    !data.transactions_aggregate.aggregate.sum.amount ||
    !data.time_sheets_aggregate.aggregate.sum.duration_minutes
  ) {
    return <div>😬 Oops! It looks like you dont have any credits.</div>;
  }

  const total = data.transactions_aggregate.aggregate.sum.amount;
  const handleEasyAmountClick = (val, id) => {
    setCustomDollarAmount(Math.trunc(val));
    setCustomCentsAmount(val.toFixed(2).toString().split(".")[1] || 0);
    setDonationSelect(id);
  };

  const handleDollarChange = (e) => {
    setCustomDollarAmount(e.target.value);

    const donationTotal = parseFloat(`${e.target.value}.${customCentsAmount}`);

    if (donationTotal > parseInt(total.slice(1))) {
      setDonationError([
        true,
        "Donation amount exceeds your total amount. Please lower your donation",
      ]);
      return;
    }

    setDonationError([false]);
  };

  const handleCentsChange = (e) => {
    setCustomCentsAmount(e.target.value);

    const donationTotal = parseFloat(`${customDollarAmount}.${e.target.value}`);

    if (donationTotal > parseFloat(total.slice(1))) {
      setDonationError([
        true,
        "Donation amount exceeds your total amount. Please lower your donation.",
      ]);
      return;
    }

    setDonationError([false]);
  };

  const handleShowDonation = () => {
    setShowDonate(true);
    setDonationComplete(false);
  }
  const handleDonationSubmit = () => {
    const donationTotal = parseFloat(
      `${customDollarAmount}.${customCentsAmount}`
    );

    if (donationTotal > parseFloat(total.slice(1))) {
      setDonationError([
        true,
        "Donation amount exceeds your total amount. Please lower your donation",
      ]);
      return;
    }

    setDisableSubmit(true);

    addTransaction({
      variables: {
        userId: user.sub,
        amount: parseFloat(`-${customDollarAmount}.${customCentsAmount}`),
      },
    });
  };


  const transactionHistory = data.transactions;

  console.log(transactionHistory);

  return (
    <div>
      <Heading
        headline="Credit"
        subHeadline="We are so excited to offer you volunteer credits! Thanks for all you do. Enjoy! 😄"
      />
      <TotalContainer>
        <IconCal css={tw`mr-4`}/>
        <div>
          <Title>Total Hours Worked:</Title>
          <ValueText>
            {(
              data.time_sheets_aggregate.aggregate.sum.duration_minutes / 60
            ).toFixed(1)}
          </ValueText>
        </div>
      </TotalContainer>
      <TotalContainer>
        <IconCredit css={tw`mr-4`} />
        <div>
          <Title>Total Available Credit:</Title>
          <p css={tw`text-xs`}>*Credits expire after 90 days</p>
          <ValueText>{total}</ValueText>
        </div>
      </TotalContainer>
      <TotalContainer>
        <IconHeart css={tw`mr-4`} />
        <div>
          <Title>Total Credit Donated:</Title>
          <ValueText>{data.users[0].transactions_aggregate.aggregate.sum.amount? data.users[0].transactions_aggregate.aggregate.sum.amount.replace(/-/, '') : '$0'}</ValueText>
        </div>
      </TotalContainer>
      <TotalContainer css={tw`items-start`}>
        <IconMoneyFolder css={tw`mr-4`} />
        <div>
          <Title>Transaction History:</Title>
          <div css={tw`mt-4`}>
            {
              transactionHistory.map(({amount, created_at, id, type}) => (
                <div key={id} css={tw`flex`}>
                  <div>
                    <div css={tw`mr-1`}>
                    {`${iconMap[type]}`}
                    </div>
                  </div>
                  <div css={tw`mb-4`}>
                    <div css={tw`mr-4`}>{format(parseISO(created_at), "LLLL do, yyyy")}</div>
                    <div css={tw`text-xs capitalize p-1 rounded inline-block leading-none bg-gray-200`}>{type}</div>
                  </div>
                  <div css={tw`ml-auto font-bold`}>
                    {`${type == 'credit' ? '+': ''}${amount}`}
                  </div>
                </div>
              ))
            }
          </div>
          <div>
            <Link to="/credit/transaction-history" css={tw`text-blue-600 underline text-center`}>See full transaction history </Link>
          </div>
        </div>
      </TotalContainer>
      <div>
        <Heading
          headline="Donate"
          subHeadline="You can donate your credit back to Beautiful Lives Boutique to help us further support our mission."
        />
        <div css={tw`flex justify-center items-center`}>
                    <Confetti
                    active={donationComplete}
                    config={config}
                  />
        </div>
        {!showDonate ? (
          <div>
            <SubmitButton onClick={() => handleShowDonation()}>
              Choose Amount
            </SubmitButton>
          </div>
        ) : (
          <div>
                            
            <div css={tw`flex mb-8`}>
              {percentages.map((val, idx) => (
                <div
                  key={idx}
                  css={tw`flex flex-col items-center justify-center mr-4`}
                >
                  <button
                    onClick={(e) =>
                      handleEasyAmountClick(val * parseInt(total.slice(1)), idx)
                    }
                    css={[
                      tw`py-2 px-4 mb-2 no-underline rounded-full bg-gray-400 text-black font-sans font-semibold text-sm border-black hover:text-white hover:bg-blb-blue-300 focus:outline-none active:shadow-none text-lg hover:text-black`,
                      {
                        backgroundColor:
                          donationSelect === idx ? "#B6E4FA" : "#f8f8f8",
                      },
                    ]}
                  >{`${val * 100}%`}</button>
                  <div>{`$${(val * parseInt(total.slice(1))).toFixed(2)}`}</div>
                </div>
              ))}
            </div>
            <div css={tw`text-lg font-semibold mb-2`}>
              Or enter custom amount
            </div>
            <div css={tw`mb-12`}>
              <span>$</span>

              <MoneyInput
                type="number"
                value={customDollarAmount}
                name="dollar"
                onChange={(e) => handleDollarChange(e)}
              />
              <span>.</span>
              <MoneyInput
                type="number"
                value={customCentsAmount}
                name="cents"
                onChange={(e) => handleCentsChange(e)}
              />
            </div>
            {donationError[0] && <div>{donationError[1]}</div>}
            <div>
              <SubmitButton
                onClick={() => handleDonationSubmit()}
                disabled={
                  disableSubmit ||
                  Boolean(!customDollarAmount) ||
                  donationError[0]
                }
                css={tw`disabled:opacity-50`}
              >
                Confirm
              </SubmitButton>
              <SubmitButton
                css={tw`bg-gray-400 text-black md:ml-4`}
                onClick={() => setShowDonate(false)}
              >
                Cancel
              </SubmitButton>
            </div>
          </div>
        )}

      </div>
      <div css={tw`mb-8 bg-gray-300 p-3 rounded text-center`}>
        You can review our credit policy{" "}
        <a
          css={tw`text-blue-900 underline text-center`}
          href={CreditPdf}
          target="_blank"
        >
          here.
        </a>
      </div>
    </div>
  );
}

export default Credit;
