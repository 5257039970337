import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

/** @jsx jsx */
import { jsx } from "@emotion/core";

function LogOutButton() {
  const { logout } = useAuth0();
  return (
    <div
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      logout
    </div>
  );
}

export default LogOutButton;
