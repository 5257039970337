import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SignIn from "./SignIn";
import App from "./App";
import Loading from "./Loading";

function Entry() {
  console.log('entry');
  const { isAuthenticated, isLoading } = useAuth0();
  if(isLoading) {
    return <Loading />
  }
  if (!isAuthenticated) {
    return (
        <SignIn />
    );
  }

  return( 
  <div>
    <App />
  </div>
  );
}

export default Entry;
