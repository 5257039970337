import React, { useEffect, useState } from "react";
import LogOutButton from "./LogOutButton";
import { useQuery, gql, useMutation } from "@apollo/client";
import tw from "twin.macro";
import { SubmitButton } from "./Form";
import Loading from "./Loading";
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table";
import { ReactComponent as IconMoney } from "./icon-transaction.svg";
import styled from "@emotion/styled";
import loadingIcon from "./loading.svg";
import { CSVLink } from "react-csv";
import {ReactComponent as IconDownload} from './icon-export.svg'



/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  format,
  isValid,
  parseISO,
  differenceInMinutes,
  formatISO,
} from "date-fns";

export const GET_USERS_CREDITS = gql`
  query GetUsersWithCredit {
    users(where: { transactions: { amount: { _is_null: false } } }) {
      id
      first_name
      last_name
      location
      email
      totalAvailableAmount: transactions_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      totalDonatedAmount: transactions_aggregate(where: {type: { _eq: "donation" }}) {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`;

const INSERT_TRANSACTION = gql`
  mutation AddCredit($amount: money!, $userId: String!) {
    insert_transactions(
      objects: [{ amount: $amount, user_id: $userId, type: "purchase" }]
    ) {
      returning {
        id
        amount
        type
        user_id
      }
    }
  }
`;



// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

export const MoneyInput = styled.input(tw`p-2 border mx-1 w-16 rounded-md`);
const DownloadCSV = SubmitButton.withComponent(CSVLink);


function AdminDashboard() {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [dollarAmount, setDollarAmount] = useState(0);
  const [centsAmount, setCentsAmount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(false)

  const [addTransaction, {loading: mutationLoading}] = useMutation(INSERT_TRANSACTION, {
    onCompleted: () => {
      setSuccessMessage(true);
    },
    refetchQueries: [{ query: GET_USERS_CREDITS }],
  });

  const columns = React.useMemo(() => [
    {
      Header: "First Name",
      accessor: "first_name",
      disableFilters: true,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true,
    },
    {
      Header: "Location",
      accessor: "location",
      disableFilters: true,
    },
    {
      Header: "Total Donated",
      accessor: "totalDonatedAmount.aggregate.sum.amount",
      disableFilters: true,
      Cell: ({value}) => value ? value.replace(/-/, '') : '$0'
    },
    {
      Header: "Available Credit",
      accessor: "totalAvailableAmount.aggregate.sum.amount",
      disableFilters: true,
    },
    {
      Header: "Transaction",
      id: "id",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleEditClick(e, row)}>
          <IconMoney />
        </button>
      ),
    },
  ]);

  const handleEditClick = (e, data) => {
    setSelectedUser({
      ...selectedUser,
      ...data.original,
    });
    setShowModal(true);
  };

  const { loading, error, data } = useQuery(GET_USERS_CREDITS);
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const d = new Date();

  const handleTimeUpdate = () => {
    addTransaction({
      variables: {
        userId: selectedUser.id,
        amount: parseFloat(`-${dollarAmount}.${centsAmount}`),
      },
    });
  };

  return (
    <div>
      <h1 css={tw`text-gray-900 text-2xl font-bold mb-2`}>User's credits</h1>
      <div css={tw`mb-4`}>
        Number of users with Credits: {data.users.length}
      </div>
      <div css={tw`flex`}>
        <CSVLink
          css={tw`block font-bold mb-4 text-center max-w-xs ml-auto flex mr-2`}
          headers={[
            {
              label: 'email', key: 'email',
            },
            {
              label: 'location', key: 'user.location'
            },
            {
              label: 'donations', key: 'totalDonatedAmount.aggregate.sum.amount'
            },
            {
              label: 'credit', key: 'totalAvailableAmount.aggregate.sum.amount'
            }
          ]}
          data={data.users}
          filename={`blb-volunteer-timeshifts-all-time-${d.getDate()}.${
            d.getMonth() + 1
          }.${d.getFullYear()}.csv`}
        >
          <span><IconDownload css={tw`mr-2`}/></span>
          Export
        </CSVLink>
      </div>
      <div css={tw`overflow-x-scroll`}>
        <UsersTable columns={columns} data={data.users} />
      </div>
      {showModal && (
        <div
          css={[
            tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`,
            { backgroundColor: "rgba(0,0,0,0.20)" },
          ]}
        >
          <div
            css={[tw`p-6 max-w-md shadow-xl bg-white`, { borderRadius: 15 }]}
          >
            <div>
              {
                mutationLoading ? (
                  <div>
                    Submitting transaction...
                    <div css={tw`flex items-center justify-center w-full h-full`}>
                      <img src={loadingIcon} width="40" />
                    </div>
                  </div>
                ) : successMessage ? (
                  <div css={tw`flex-col justify-center items-center`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#4BCA81" width="60px" css={tw`mx-auto mb-6`}><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"/></svg>
                      <div css={tw`mb-8 text-center`}>This transaction was sucessful. If the total available credit has not updated, pease refresh. </div>
                      <button
                          disabled={mutationLoading}
                          css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white cursor-pointer w-full`}
                          onClick={(evt) =>{ 
                            setSuccessMessage(false)
                            setShowModal(false)
                          }}
                        >
                          Close
                        </button>
                  </div>
                )
               : (
                  <div>
                  <div
                    css={tw`capitalize`}
                  >{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
                  <div
                    css={tw`mb-4`}
                  >{`Credits available: ${selectedUser.totalAvailableAmount.aggregate.sum.amount}`}</div>
                  <div>Credits Used for this transaction:</div>
                  <div>
                    <div css={tw`mb-4`}>
                      <span>$</span>
  
                      <MoneyInput
                        type="number"
                        value={dollarAmount}
                        name="dollar"
                        onChange={(e) => setDollarAmount(e.target.value)}
                      />
                      <span>.</span>
                      <MoneyInput
                        type="number"
                        value={centsAmount}
                        name="cents"
                        onChange={(e) => setCentsAmount(e.target.value)}
                      />
                    </div>
  
                    <div css={tw`flex`}>
                      <div css={tw`w-1/2 pr-2`}>
                        <div
                          css={tw`bg-gray-400 py-2 text-center rounded-md cursor-pointer`}
                          onClick={(evt) => setShowModal(false)}
                        >
                          Cancel
                        </div>
                      </div>
                      <div css={tw`w-1/2 pl-2`}>
                        <button
                          disabled={mutationLoading}
                          css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white cursor-pointer w-full`}
                          onClick={(evt) => handleTimeUpdate()}
                        >
                          submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                )
              }

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function UsersTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    visibleColumns,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );

  return (
    <div>
      <table css={tw`text-center w-full`} {...getTableProps()}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              css={tw`bg-blb-gray-300 rounded-md`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th css={tw`px-4 py-5`} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td css={tw`px-4 py-5`} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

// Create an editable cell renderer
function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
}

export default AdminDashboard;
