import React, { useEffect, useState } from "react";
import LogOutButton from "./LogOutButton";
import { useQuery, gql, useMutation } from "@apollo/client";
import tw from "twin.macro";
import { CSVLink } from "react-csv";
import { SubmitButton } from "./Form";
import Loading from "./Loading";
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table";
import {ReactComponent as IconDownload} from './icon-export.svg'
import { ReactComponent as IconEdit } from "./icon-edit-pencil.svg";
import styled from "@emotion/styled";
import TimerEdit from "./TimerEdit";
import loadingIcon from "./loading.svg";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";

import {
  format,
  isValid,
  parseISO,
  differenceInMinutes,
  formatISO,
  set
} from "date-fns";


/** @jsx jsx */
import { jsx } from "@emotion/core";

export const GET_USERS = gql`
  query GetUsers {
    users(order_by: { created_at: desc }) {
      id
      created_at
      first_name
      last_name
      username
      email
      phone_number
      preferred_notification_method
      group_type
      group_coordination
      interested_role
      photo_consent
      day_availability
      birthdate
      location
      emergency_name
      emergency_phone_number
      emergency_relationship
      required_service_hours
      volunteering_frequency
      day_availability
      time_availability
      interested_role
      additional_experience
      created_at
      schedule_allow
      under_age
      transactions_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`;

const INSERT_TIMER = gql`
  mutation StartTimer($startTime: timestamptz!, $userId: String!, $endTime: timestamptz!, $durationMinutes: Int!) {
    insert_time_sheets(objects: [{ from: $startTime, to: $endTime user_id: $userId, duration_minutes:  $durationMinutes }]) {
      returning {
        id
        from
        to
        user_id
        duration_minutes
      }
    }
  }
`;

const INSERT_EVENT = gql`
  mutation InsertEvent(
    $dateStart: timestamptz!
    $dateEnd: timestamptz!
    $locationId: Int!
    $userId: String!
  ) {
    insert_events(
      objects: [
        {
          date_start: $dateStart
          date_end: $dateEnd
          location_id: $locationId
          user_id: $userId
        }
      ]
    ) {
      returning {
        id
        date_start
        date_end
        location_id
      }
    }
  }
`;

const UPDATE_USER_SCHEDULE_ALLOW = gql`
  mutation UpdateUserScheduleAccess($id: String!, $scheduleAllow: Boolean!) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { schedule_allow: $scheduleAllow }
    ) {
      affected_rows
    }
  }
`;


const INSERT_TRANSACTION = gql`
  mutation AddCredit($amount: money!, $userId: String!) {
    insert_transactions(
      objects: [{ amount: $amount, user_id: $userId, type: "admin" }]
    ) {
      returning {
        id
        amount
        type
        user_id
      }
    }
  }
`;

export const INSERT_TRANSACTION_FROM_TIME_SHIFT = gql`
  mutation AddCredit($amount: money!, $userId: String!, $shiftId: Int!) {
    insert_transactions(
      objects: [
        {
          amount: $amount
          user_id: $userId
          shift_id: $shiftId
          type: "credit"
        }
      ]
    ) {
      returning {
        id
        amount
        type
        user_id
        shift_id
      }
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const locationMap = {
  "Bentonville": 1,
  "Fayetteville": 2,
  "Siloam Springs": 3
}

const MoneyInput = styled.input(tw`p-2 border mx-1 w-16 rounded-md`);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search:{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

const DownloadCSV = SubmitButton.withComponent(CSVLink);

function AdminDashboard() {
  const [showModal, setShowModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState("");
  const [dollarAmount, setDollarAmount] = useState(0);
  const [centsAmount, setCentsAmount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(new Date())
  const [scheduleStartTime, setScheduleStartTime] = useState("10:00")
  const [scheduleEndTime, setScheduleEndTime] = useState("12:00")
  const [shiftSuccess, setShiftSuccess] = useState(false)




  const [selectedShift, setSelectedShift] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [addTransaction] = useMutation(INSERT_TRANSACTION, {
    onCompleted: () => {
      setShowModal(false);
    },
    refetchQueries: [{ query: GET_USERS }],
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }]
  });

  const [addTransactionFromTimeShift, { data: transRetData }] = useMutation(
    INSERT_TRANSACTION_FROM_TIME_SHIFT
  );


  const [addTimeShift, { loading: mutationLoading }] = useMutation(INSERT_TIMER, {
    onCompleted(data) {
      let credits = (data.insert_time_sheets.returning[0].duration_minutes * (4 / 60)).toFixed(2)
      let creditAmount = selectedUser.transactions_aggregate.aggregate.sum.amount;

    console.log(creditAmount);

      if(creditAmount >= 250) {
        return;
      }
  
      if (parseFloat(creditAmount) + parseFloat(credits) > 250.00) {
        credits = 250 - creditAmount
      }

      addTransactionFromTimeShift({
        variables: {
          amount: credits,
          shiftId: data.insert_time_sheets.returning[0].id,
          userId: data.insert_time_sheets.returning[0].user_id
        }
      })


      console.log(credits);
      console.log(data);
      setShowTimeModal(false);


    },
  });

  const [addEvent, { data: scheduleData }] = useMutation(INSERT_EVENT, {
    onCompleted(scheduleData) {
      setShiftSuccess(true)
    },
  });


  const columns = React.useMemo(() => [
    {
      Header: "First Name",
      accessor: "first_name",
      disableFilters: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      disableFilters: true
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      disableFilters: true
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true
    },
    {
      Header: 'Location',
      accessor: 'location',
      disableFilters: true
    },
    {
      Header: 'Underage',
      accessor: 'under_age',
      disableFilters: true
    },
    {
      Header: 'Photo',
      accessor: 'photo_consent',
      disableFilters: true
    },
    {
      Header: "Available to schedule",
      accessor: "schedule_allow",
      Cell: (cell) => (
        <Switch initialAccess={cell.value} userId={cell.row.original.id} />
      ),
      disableFilters: true
    },
    {
      Header: "Add Credits",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      id: "id",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleEditClick(e, row)}>
          <IconEdit />
        </button>
      ),
    },
    {
      Header: "Add Shift",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      id: "shiftId",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleEditTimeClick(e, row)}>
          <IconEdit />
        </button>
      ),
    },
    {
      Header: "Schedule Shift",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      id: "scheduledShiftId",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleScheduleShiftClick(e, row)}>
          <IconEdit />
        </button>
      ),
    },
    {
      Header: "Delete User",
      accessor: "delete",
      Cell: ({ row }) => (
        <button onClick={(e) => handleDeleteClick(e, row)}>
          <div css={tw`text-red-500`}>DELETE</div>
        </button>
      ),
    },
  ]);

  const handleEditClick = (e, data) => {
    setSelectedUser({
      ...selectedUser,
      ...data.original,
    });
    setShowModal(true);
  };

  const handleEditTimeClick = (e, data) => {
    setSelectedUser({
      ...selectedUser,
      ...data.original,
    });

    setShowTimeModal(true);
  };

  const handleScheduleShiftClick = (e, data) => {
    console.log(data);
    setSelectedUser({
      ...selectedUser,
      ...data.original,
    });

    setShowScheduleModal(true);
  };

  const handleDeleteClick = (e, data) => {

    deleteUser({
      variables: {
        id: data.original.id
      }
    })
  }

  const handleAddTimeShift = (fromTime, toTime) => {
    let credits = (differenceInMinutes(toTime, fromTime) * (4 / 60)).toFixed(2)
    let creditAmount = selectedUser.transactions_aggregate.aggregate.sum.amount;

    console.log(credits);
    console.log(creditAmount);

    addTimeShift({
      variables: {
        userId: selectedUser.id,
        startTime: formatISO(fromTime),
        endTime: formatISO(toTime),
        durationMinutes: differenceInMinutes(toTime, fromTime),
      }
    })


  }

  const { loading, error, data } = useQuery(GET_USERS, {notifyOnNetworkStatusChange: true});
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const d = new Date();

  const handleTimeUpdate = () => {
    addTransaction({
      variables: {
        userId: selectedUser.id,
        amount: parseFloat(`${dollarAmount}.${centsAmount}`),
      },
    });
  };

  const handleShiftSubmit = () => {

    console.log(scheduleDate, scheduleStartTime);
    const shiftStart = set(scheduleDate, {hours: parseInt(scheduleStartTime), minutes: 0});
    const shiftEnd = set(scheduleDate, { hours: parseInt(scheduleEndTime), minutes: 0});
    
    addEvent({
      variables: {
        dateStart: formatISO(shiftStart),
        dateEnd: formatISO(shiftEnd),
        locationId: locationMap[selectedUser.location],
        userId: selectedUser.id,
      },
    });
  };

  const handleShiftSuccessClose = (evt) => {
    setShowScheduleModal(false);
    setShiftSuccess(false);
    setScheduleDate(new Date());
    setScheduleStartTime("10:00");
    setScheduleEndTime("12:00")

  }

  return (
    <div>
      <h1 css={tw`text-gray-900 text-2xl font-bold mb-2`}>Export Users</h1>
      <div css={tw`mb-4`}>Number of users: {data.users.length}</div>
      <div css={tw`flex`}>
        <CSVLink
          css={tw`block font-bold mb-4 text-center max-w-xs ml-auto flex mr-2`}
          data={data.users}
          filename={`blb-volunteer-users-all-time-${d.getDate()}.${
            d.getMonth() + 1
          }.${d.getFullYear()}.csv`}
        >
          <span><IconDownload css={tw`mr-2`}/></span>
          Export
        </CSVLink>
      </div>
      <UsersTable columns={columns} data={data.users} />
      {showModal && (
        <div
          css={[
            tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`,
            { backgroundColor: "rgba(0,0,0,0.20)" },
          ]}
        >
          <div
            css={[tw`p-6 max-w-md shadow-xl bg-white`, { borderRadius: 15 }]}
          >
            <div>
              <div>
                <div
                  css={tw`capitalize`}
                >{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
                <div
                  css={tw`mb-4`}
                >{`Credits available: ${selectedUser.transactions_aggregate.aggregate.sum.amount}`}</div>
                <div>Add credits to user's account:</div>
                <div>
                  <div css={tw`mb-4`}>
                    <span>$</span>

                    <MoneyInput
                      type="number"
                      value={dollarAmount}
                      name="dollar"
                      onChange={(e) => setDollarAmount(e.target.value)}
                    />
                    <span>.</span>
                    <MoneyInput
                      type="number"
                      value={centsAmount}
                      name="cents"
                      onChange={(e) => setCentsAmount(e.target.value)}
                    />
                  </div>

                  <div css={tw`flex`}>
                    <div css={tw`w-1/2 pr-2`}>
                      <div
                        css={tw`bg-gray-400 py-2 text-center rounded-md cursor-pointer`}
                        onClick={(evt) => setShowModal(false)}
                      >
                        Cancel
                      </div>
                    </div>
                    <div css={tw`w-1/2 pl-2`}>
                      <div
                        css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white cursor-pointer`}
                        onClick={(evt) => handleTimeUpdate()}
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                  <div css={tw`text-center text-xs text-red-400`}>Warning: this will not edit current credit balance but add additional credits</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTimeModal && (
        <div
          css={[
            tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`,
            { backgroundColor: "rgba(0,0,0,0.20)" },
          ]}
        >
          <div
            css={[tw`p-6 max-w-md shadow-xl bg-white`, { borderRadius: 15 }]}
          >
                          {
                mutationLoading ? (
                  <div>
                    Submitting time shift...
                    <div css={tw`flex items-center justify-center w-full h-full`}>
                      <img src={loadingIcon} width="40" />
                    </div>
                  </div>
                ) : (
                  <div>
                  <div css={tw`mb-4`}>
                    <div css={tw`font-bold`}> Select Day </div>
                  <DatePicker onChange={(date) => setSelectedShift({from: date, to: date })} selected={selectedShift.from} />
                  </div>
                  <TimerEdit
                  fromDate={selectedShift.from}
                  toDate={
                    isValid(selectedShift.to)
                      ? selectedShift.to
                      : selectedShift.from
                  }
                  submitLabel="Update"
                  cancelLabel="Cancel"
                  onSubmit={handleAddTimeShift}
                  onCancel={setShowTimeModal}
                  showLabel={false}
                />
                </div>
                )
              }

          </div>
        </div>
      )}
      {showScheduleModal && (
        <div
        css={[
          tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`,
          { backgroundColor: "rgba(0,0,0,0.20)" },
        ]}
      >
        <div
          css={[tw`p-6 max-w-md shadow-xl bg-white`, { borderRadius: 15 }]}
        >
          <div>
            <div>
              {
                shiftSuccess ? (
                  <div>
                  <div>The shift has been succesfully scheduled</div>
                  <div css={tw`w-1/2 pr-2`}>
                  <div
                    css={tw`bg-gray-400 py-2 text-center rounded-md cursor-pointer`}
                    onClick={(evt) => handleShiftSuccessClose(evt)}
                  >
                    Close
                  </div>
                </div>
                </div>
                ) : (
                  <div>
                  <div
                  css={tw`capitalize`}
                >{`${selectedUser.first_name} ${selectedUser.last_name}`}</div>
                <div>Schedule an upcoming shift for this user</div>
                <div>
                  <div css={tw`mb-4`}>
                    <div> Select Day </div>
                  <DatePicker onChange={setScheduleDate} selected={scheduleDate} />
                  </div>
  
                  <div css={tw`mb-4`}>
                    <div> Select Start Time </div>
                    <TimePicker onChange={setScheduleStartTime} value={scheduleStartTime} />
                  </div>
                  <div css={tw`mb-4`}>
                  <div> Select End Time </div>
                    <TimePicker onChange={setScheduleEndTime} value={scheduleEndTime} />
                  </div>
  
                  <div css={tw`flex`}>
                    <div css={tw`w-1/2 pr-2`}>
                      <div
                        css={tw`bg-gray-400 py-2 text-center rounded-md cursor-pointer`}
                        onClick={(evt) => setShowScheduleModal(false)}
                      >
                        {shiftSuccess ? "Close" : "Cancel"}
                      </div>
                    </div>
                    <div css={tw`w-1/2 pl-2`}>
                      {
                        !shiftSuccess && (
                          <div
                          css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white cursor-pointer`}
                          onClick={(evt) => handleShiftSubmit()}
                        >
                          Submit
                        </div>
                        )
                      }

                      </div>
                      </div>
                      </div>
                      </div>
                )
              }

            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

function UsersTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetRowState: false
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination,
  );

  return (
    <div>
      <table css={tw`text-center w-full`} {...getTableProps()}>
        <thead>
        <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              css={tw`bg-blb-gray-300 rounded-md`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th css={tw`px-4 py-5`} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td css={tw`px-4 py-5`} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function Switch({ initialAccess, userId }) {
  const [allowAccess, setAllowAccess] = useState(initialAccess);

  const [updateUserAllowedAccess, { loading: mutationLoading }] = useMutation(
    UPDATE_USER_SCHEDULE_ALLOW
  );

  const handleChange = (e) => {
    setAllowAccess(e.target.checked);
    updateUserAllowedAccess({
      variables: {
        id: userId,
        scheduleAllow: e.target.checked,
      },
    });
  };

  useEffect(() => {
    setAllowAccess(initialAccess);
  }, [initialAccess]);

  return (
    <React.Fragment>
      <label
        css={[
          tw`relative inline-block`,
          {
            width: 50,
            height: 30,
          },
        ]}
      >
        <input
          css={[
            tw`opacity-0 w-0 h-0`,
            {
              "&: checked + span": {
                backgroundColor: "#1890FF",
                "&:: before" : {
                  transform: 'translateX(20px)'
                }
              },
            },
          ]}
          type="checkbox"
          id="1"
          onChange={handleChange}
          checked={allowAccess}
        />
        <span
          css={[
            tw`absolute top-0 left-0 right-0 bottom-0 rounded-full transition ease-in duration-500`,
            {
              backgroundColor: '#C4C4C4',
              "&:: before": {
                position: "absolute",
                content: ' "" ',
                height: 22,
                width: 22,
                left: 4,
                bottom: 4,
                backgroundColor: "#fff",
                transition: ".4s",
                borderRadius: "50%",
              },
            },
          ]}
        ></span>
      </label>
    </React.Fragment>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
}

export default AdminDashboard;
