import React, {useState} from "react";
import tw from "twin.macro";
import Confetti from "react-dom-confetti";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { format } from "date-fns";

const config = {
    angle: 90,
    spread: 150,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

function CompletionScreen({ shiftStart, shiftEnd, children }) {
    const [active, setActive] = useState(false);

  return (
    <div
      css={[
        tw`p-6 text-center`,
        { backgroundColor: "#F1F3F4", borderRadius: 15 },
      ]}
    >

      <div css={tw`mb-4 text-3xl`}>You’ve scheduled<br/> a shift</div>
      <div css={tw`mb-6`}>
        WooHoo!! You are scheduled for {format(shiftStart, "EEEE, LLLL do")}{" "}
        from {format(shiftStart, "h:mm aaaa")} to{" "}
        {format(shiftEnd, "h:mm aaaa")}! Can't wait to see you there 🙂.
      </div>
      <div css={tw`flex items-center text-center justify-center`}>
        <div>
          <Confetti
            ref={() => {
              setActive(true);
            }}
            active={active}
            config={config}
          />
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default CompletionScreen;
