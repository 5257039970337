import React, { useEffect, useState } from "react";
import tw from "twin.macro";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { partnerInfo } from "./partnerInfo";
import { ReactComponent as Arrow } from "./angle-right-solid.svg";

function PartnerAcordian() {
    const [active, setActive] = useState(false)
  return (
    <div>
      <div css={tw`font-bold`}>Learn more about our nonprofit partners:</div>
      <div css={tw`mt-4`}>
        {partnerInfo.map(({ title, content }, idx) => (
          <div key={idx}>
              <AcordianItem  title={title} content={content}/>
          </div>
        ))}
      </div>
    </div>
  );
}

function AcordianItem({title, content}) {
    const [open, setOpen] = useState(false);
    return (
        <div
        css={tw`p-5 border border-2 rounded-lg border-gray-300 cursor-pointer mb-8`}
        onClick={() => setOpen(!open)}
      >
        <div css={tw`flex`}>
          <div css={tw`font-bold`}>{title}</div>
          <Arrow width="12" css={[tw`ml-auto transform rotate-90`, {
              tansform: open ? 'rotate(-90deg)' : 'rotate(90deg)' 
          }]}/>
        </div>
        <div css={[tw`mt-4`, {
            display: open ? 'block' : 'none'
        }]}>{content}</div>
      </div>
    )
}

export default PartnerAcordian;
