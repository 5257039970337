import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserDashboard from "./UserDashboard";
import SignIn from "./SignIn";
import PrivateRoute from "./PrivateRoute";
import AdminDashboard from "./AdminDashboard";

/** @jsx jsx */
import { jsx } from "@emotion/core";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  gql,
  useQuery,
} from "@apollo/client";
import Loading from "./Loading";
import { Switch, Route, Redirect } from "react-router-dom";
import VolunteerPreferences from "./accountSettings/VolunteerPreferences";
import Registration from "./registration/Registration";
import RegPersonalInfo from "./registration/PersonalInfo";
import RegVolunteerPreferences from "./registration/VolunteerPreferences";
import RegistrationComplete from "./registration/RegistrationComplete";
import Account from "./Account";
import Layout from "./Layout";
import PersonalInfo from "./accountSettings/PersonalInfo";
import Policies from "./accountSettings/Policies";
import Schedule from "./Schedule";
import NewUserScreen from "./NewUserScreen";
import AdminSchedule from "./AdminSchedule";
import AdminTime from "./AdminTime";
import Timer from "./Timer";
import Credit from "./Credit";
import AdminCredit from './AdminCredit'
import AdminCms from './AdminCMS'
import TransactionHistory from './TransactionHistory'
import Orientation from "./registration/Orientation";
import AdminReports from "./AdminReports";
import AdminTransactions from "./AdminTransactions";

export const USER_NEW = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      is_new
    }
  }
`;

function App() {
  // used state to get accessToken through getTokenSilently(), the component re-renders when state changes, thus we have
  // our accessToken in apollo client instance.
  const [accessToken, setAccessToken] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      // getTokenSilently() returns a promise
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        console.log(e);
      }
    };

    getAccessToken();
  }, []);

  const createApolloClient = (authToken) => {
    return new ApolloClient({
      link: new HttpLink({
        uri: "https://blb-volunteer.herokuapp.com/v1/graphql",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
      cache: new InMemoryCache(),
    });
  };

  const client = createApolloClient(accessToken);

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route
          exact
          path={[
            "/registration",
            "/login",
            "/registration/personal-info",
            "/registration/volunteer-preferences",
            "/registration/complete",
            "/registration/orientation",
          ]}
        >
          <Layout simpleMenu>
            <Switch>
              <PrivateRoute
                exact
                path="/registration"
                component={Registration}
              />
              <PrivateRoute
                path="/registration/personal-info"
                component={RegPersonalInfo}
              />
              <PrivateRoute
                exact
                path="/registration/volunteer-preferences"
                component={RegVolunteerPreferences}
              />
              <Route
                path="/registration/complete"
                component={RegistrationComplete}
              />
              <Route
                path="/registration/orientation"
                component={Orientation}
              />
            </Switch>
          </Layout>
        </Route>
        <Route
          exact
          path={[
            "/dashboard",
            "/admin-dashboard",
            "/account-settings",
            "/account-settings/personal-info",
            "/account-settings/volunteer-preferences",
            "/account-settings/policies",
            "/schedule",
            "/timer",
            "/credit",
            "/credit/transaction-history",
            "/",
            "/admin/user/new/:id",
            "/admin/schedule",
            "/admin/time",
            "/admin/credit",
            "/admin/cms",
            "/admin/reports",
            "/admin/transactions"
          ]}
        >
          <Layout>
            <Switch>
              <PrivateRoute
                exact
                path="/account-settings/personal-info"
                component={PersonalInfo}
              />
              <PrivateRoute
                exact
                path="/account-settings/policies"
                component={Policies}
              />
              <PrivateRoute
                exact
                path={`/account-settings/volunteer-preferences`}
                component={VolunteerPreferences}
              />
              <PrivateRoute path="/dashboard" component={UserDashboard} exact />
              <PrivateRoute
                path="/admin-dashboard"
                exact
                component={AdminDashboard}
              />
              <PrivateRoute path="/schedule" component={Schedule} exact />
              <PrivateRoute
                path="/admin/user/new/:username"
                exact
                component={NewUserScreen}
              />
              <PrivateRoute
                path="/account-settings"
                component={Account}
                exact
              />
              <PrivateRoute
                path="/admin/schedule"
                component={AdminSchedule}
                exact
              />
              <PrivateRoute path="/admin/time" component={AdminTime} exact />
              <PrivateRoute
                path="/admin/credit"
                component={AdminCredit}
                exact
              />
              <PrivateRoute
                path="/admin/cms"
                component={AdminCms}
                exact
              />
              <PrivateRoute
                path="/admin/reports"
                component={AdminReports}
                exact
              />
              <PrivateRoute
                path="/admin/transactions"
                component={AdminTransactions}
                exact
              />
              <PrivateRoute path="/timer" component={Timer} exact />
              <PrivateRoute path="/credit" component={Credit} exact />
              <PrivateRoute path="/credit/transaction-history" component={TransactionHistory} exact />
            </Switch>
          </Layout>
        </Route>
      </Switch>
      <NewUserCheck />
    </ApolloProvider>
  );
}

function NewUserCheck() {
  const { user } = useAuth0();

  const { loading, error, data } = useQuery(USER_NEW, {
    variables: { id: user.sub },
  });

  const isNew = data?.users[0].is_new;

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error) return `Error! ${error.message}`;

  if (isNew) {
    return <Redirect from="/" to="/registration" />;
  }

  return <Redirect from="/" to={window.location.pathname} />;
}

export default App;