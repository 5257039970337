import React, { useReducer, useEffect, useState } from "react";
import tw from "twin.macro";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import loadingIcon from "../loading.svg";
import BreadCrumbs from "../Breadcrumbs";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Loading from "../Loading";
import {
  InputContainer,
  SelectButton,
  NonInputLabel,
  Input,
  Label,
  SubmitButton,
  FormContainer,
  SelectButtonWrapper
} from "../Form";

import { Heading } from "../Account";

const UPDATE_USER = gql`
  mutation UpdateTodo(
    $id: String!
    $groupCoordination: String!
    $groupType: String!
    $location: String!
    $requiredServiceHours: String!
    $volunteeringFrequency: String!
    $dayAvailability: String!
    $timeAvailability: String!
    $additionalExperience: String!
    $interestedRole: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        group_coordination: $groupCoordination
        group_type: $groupType
        location: $location
        required_service_hours: $requiredServiceHours
        volunteering_frequency: $volunteeringFrequency
        day_availability: $dayAvailability
        time_availability: $timeAvailability
        additional_experience: $additionalExperience
        interested_role: $interestedRole
        is_new: false
      }
    ) {
      affected_rows
    }
  }
`;

export const USER_INFO = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      group_coordination
      group_type
      location
      required_service_hours
      volunteering_frequency
      day_availability
      time_availability
      interested_role
      additional_experience
    }
  }
`;


const Textarea = Input.withComponent("textarea");

function VolunteerPreferences() {
  let { path } = useRouteMatch();

  let [a, ...breadCrumbs] = path.split("/");
  return (
    <div>
      <BreadCrumbs breadcrumbs={breadCrumbs} />
      <Heading headline="Volunteer Preferences" />
      <div>
        <Form />
      </div>
    </div>
  );
}

function Form() {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      groupCoordination: "",
      groupType: "",
      location: "",
      requiredServiceHours: "",
      volunteeringFrequency: "",
      dayAvailability: [],
      timeAvailability: [],
      additionalExperience: "",
      interestedRole: [],
    }
  );

  const [inputActive, setInputActive] = useState(false);

  const { user } = useAuth0();

  const history = useHistory();

  const [updateUser, { loading: mutationLoading }] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      history.push("/account-settings");
    },
  });

  const { loading, error, data } = useQuery(USER_INFO, {
    variables: { id: user.sub },
  });

  useEffect(() => {
    let mounted = true;
    if (data) {
      const user = data.users[0];

      if (mounted) {
        setUserInput({
          groupCoordination: user.group_coordination || "",
          location: user.location || "",
          groupType: user.group_type || "",
          requiredServiceHours: user.required_service_hours || "",
          volunteeringFrequency: user.volunteering_frequency || "",
          dayAvailability: user.time_availability
            ? user.day_availability.split(",")
            : [],
          timeAvailability: user.time_availability
            ? user.time_availability.split(",")
            : [],
          interestedHours: user.interested_hours || "",
          additionalExperience: user.additional_experience || "",
          interestedRole: user.interested_role
            ? user.interested_role.split(",")
            : [],
        });
      }
    }

    return () => (mounted = false);
  }, [data]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleMultipleValueInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;

    if (userInput[name].includes(newValue)) {
      const options = userInput[name];
      options.splice(options.indexOf(newValue), 1);

      setUserInput({ [name]: [...options] });
    } else {
      setUserInput({ [name]: [...userInput[name], newValue] });
    }
  };

  const handleSubmit = (evt) => {
    const {
      groupCoordination,
      groupType,
      location,
      requiredServiceHours,
      volunteeringFrequency,
      dayAvailability,
      timeAvailability,
      additionalExperience,
      interestedRole,
    } = userInput;
    updateUser({
      variables: {
        id: user.sub,
        groupCoordination,
        groupType,
        location,
        requiredServiceHours,
        volunteeringFrequency,
        dayAvailability: dayAvailability.join(),
        timeAvailability: timeAvailability.join(),
        additionalExperience,
        interestedRole: interestedRole.join(),
      },
      optimisticResponse:true
    });
  };

  const handleInputActiveChange = (input) => setInputActive(input);

  const groupOptions = ["Yes", "No"];

  const groupTypeOptions = ["Friend group", "A club", "Church group", "Other"];

  const locationOptions = ["Bentonville", "Fayetteville", "Siloam Springs"];

  const volunteerFrequencyOptions = ["Weekly", "One-time", "Drop-in"];

  const daysAvailableOptions = ["M", "T", "W", "Th", "F", "S"];

  const timesAvailableOptions = ["Morning", "Evening"];

  const interestedHoursOptions = [
    "1 hour",
    "2 hours",
    "3 hours",
    "4 hours",
    "5 hours",
  ];

  const roleOptions = [
    "Coordinate clothing drive",
    "Sales floor",
    "Housekeeping",
    "Visual displays",
    "Modeling",
    "Other",
  ];

  const inputs = ["fullName", "phoneNumber", "relationship"];

  return (
    <div>
      <FormContainer
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit(evt);
        }}
      >
        <InputContainer>
          <NonInputLabel>
            Which location will you be volunteering at?
          </NonInputLabel>
          <div css={tw`md:flex`}>
            {locationOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="location"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["location"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        <InputContainer>
          <NonInputLabel>
            Are you coordinating a volunteer opportunity for a group?
          </NonInputLabel>
          <div css={tw`md:flex`}>
            {groupOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="groupCoordination"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option === userInput["groupCoordination"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        {userInput.groupCoordination === "Yes" && (
          <InputContainer>
          <Label>Group name:</Label>
          <Input
            name="groupType"
            type="text"
            value={userInput["groupType"]}
            onChange={(evt) => handleInputChange(evt)}
            onClick={(evt) => handleInputActiveChange("groupType")}
          />
        </InputContainer>
        )}

        <InputContainer>
          <NonInputLabel css={tw`mb-2 block`}>
            Are you interested in volunteering formally as part of a school or
            other program to complete required service hours?
          </NonInputLabel>
          <div css={tw`flex`}>
            <SelectButton
              type="button"
              name="requiredServiceHours"
              value="yes"
              onClick={(evt) => handleInputChange(evt)}
              active={"yes" == userInput["requiredServiceHours"]}
            >
              Yes
            </SelectButton>
            <SelectButton
              type="button"
              name="requiredServiceHours"
              value="no"
              onClick={(evt) => handleInputChange(evt)}
              active={"no" == userInput["requiredServiceHours"]}
            >
              No
            </SelectButton>
          </div>
        </InputContainer>

        <InputContainer>
          <NonInputLabel>
            How often are you (or your group) looking to volunteer?
          </NonInputLabel>
          <div css={tw`flex`}>
            {volunteerFrequencyOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="volunteeringFrequency"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["volunteeringFrequency"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>

        <InputContainer>
          <NonInputLabel>
            When would you (or your group) like to volunteer?
          </NonInputLabel>
          <div css={tw`flex`}>
            {daysAvailableOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="dayAvailability"
                value={option}
                onClick={(evt) => handleMultipleValueInputChange(evt)}
                active={userInput["dayAvailability"].includes(option)}
              >
                {option}
              </SelectButton>
            ))}
          </div>
          <div css={tw`flex`}>
            {timesAvailableOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="timeAvailability"
                value={option}
                onClick={(evt) => handleMultipleValueInputChange(evt)}
                active={userInput["timeAvailability"].includes(option)}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>

        <InputContainer>
          <NonInputLabel>
            As a volunteer your primary role will be to tag/hang/steam the
            clothing, however, we may utilize you for other tasks on occasion.
            Please choose all roles that interest you:
          </NonInputLabel>
          <div css={tw`flex flex-wrap`}>
            {roleOptions.map((option, idx) => (
              <SelectButton
                css={tw`md:w-5/12 md:flex-grow`}
                key={idx}
                type="button"
                name="interestedRole"
                value={option}
                onClick={(evt) => handleMultipleValueInputChange(evt)}
                active={userInput["interestedRole"].includes(option)}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>

        <InputContainer>
          <NonInputLabel>
            Any special skills, talents, or past experience that you would like
            to contribute to the mission?
          </NonInputLabel>
          <Textarea
            name="additionalExperience"
            value={userInput.additionalExperience}
            onChange={(evt) => handleInputChange(evt)}
            height="100"
          />
        </InputContainer>

        <SubmitButton type="submit" css={tw`flex justify-center`}>
          {mutationLoading ? <img src={loadingIcon} width="40" /> : "Save"}
        </SubmitButton>
      </FormContainer>
    </div>
  );
}

export default VolunteerPreferences;
