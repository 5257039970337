import React from "react";
import loading from "./loading.svg";

function Loading() {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white"
  }

  return (
    <div style={style}>
      <img width="100" src={loading} alt="loading" />
    </div>
  )
}

export default Loading;