import React, { useState } from "react";
import tw from "twin.macro";
import logo from "./logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, NavLink } from "react-router-dom";
import { ReactComponent as IconLogout } from "./logout.svg";
import { ReactComponent as IconHome } from "./home.svg";
import { ReactComponent as IconAccount } from "./cog.svg";
import { ReactComponent as IconCalendar } from "./icon-calendar.svg";
import { ReactComponent as IconUsers } from "./icon-users.svg";
import { ReactComponent as IconClock } from "./icon-clock.svg";
import { ReactComponent as IconCoin} from "./icon-coin.svg";
import { ReactComponent as IconPaperEdit} from './icon-edit-paper-pencil.svg'
import styled from "@emotion/styled";
import Loading from "./Loading";
import bars from "./bars.svg";
import close from './icon-close.svg'
import { useQuery } from "@apollo/client";

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Profile, { USER_INFO } from "./Profile";

const MenuButton = styled.div(
  tw`py-3 px-2 rounded-lg flex flex-col justify-center relative`
);

const activeLink = {
  color: "#2A4754",
  fontWeight: "bold",
  backgroundColor: "#F8F8F8",
  display: "block",
  borderRadius: 12,
};

const menuItems = [
  {
    title: "Dashboard",
    Icon: IconHome,
    link: "/dashboard",
    newFeature: false,
  },
  {
    title: "Account",
    Icon: IconAccount,
    link: "/account-settings",
    newFeature: false,
  },
  {
    title: "Schedule",
    Icon: IconCalendar,
    link: "/schedule",
    newFeature: false,
  },
  {
    title: "Time Clock",
    Icon: IconClock,
    link: "/timer",
    newFeature: false,
  },
  {
    title: "Credit",
    Icon: IconCoin,
    link: '/credit',
    newFeature: false
  }
];

const adminMenuItems = [
  {
    title: "Schedules",
    Icon: IconCalendar,
    link: "/admin/schedule",
  },
  {
    title: "Users",
    Icon: IconUsers,
    link: "/admin-dashboard",
  },
  {
    title: "Time Shifts",
    Icon: IconClock,
    link: "/admin/time",
  },
  {
    title: "Transactions",
    Icon: IconClock,
    link: "/admin/transactions"
  },
  {
    title: "Credits",
    Icon: IconCoin,
    link: "/admin/credit",
  },
  {
    title: "CMS",
    Icon: IconPaperEdit,
    link: "/admin/cms",
  },
  {
    title: "Reports",
    Icon: IconPaperEdit,
    link: "/admin/reports",
  },

];

function Menu() {
  const { logout, user, isAuthenticated } = useAuth0();
  const location = useLocation();

  const isActive = (link) => location.pathname.includes(link);

  const { loading, error, data } = useQuery(USER_INFO, {
    variables: { id: user.sub },
  });

  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <div
        css={tw`hidden w-full w-64 lg:flex py-2 px-6 border-r-2 border-gray-100 justify-between md:flex-col md:h-full md:justify-start md:space-y-6 bg-white`}
      >
        <div css={tw`hidden md:block md:mt-8`}>
          <img
            src={logo}
            alt="beautiful lives boutique volunteer app"
            css={tw`mx-auto`}
          />
        </div>
        <Profile />
        <div>
          {menuItems.map(({ title, Icon, link, newFeature }, idx) => (
            <NavLink to={link} activeStyle={activeLink} key={idx}>
              <MenuButton>
                <div css={tw`flex items-center`}>
                  <Icon css={tw`mr-6`} />
                  <div>{title}</div>
                  {newFeature && (
                    <div
                      css={tw`bg-blb-blue-500 text-xs text-white py-1 px-2 rounded ml-4`}
                    >
                      new
                    </div>
                  )}
                </div>
              </MenuButton>
            </NavLink>
          ))}
          {data && data.users[0].admin && (
            <div css={tw`mt-8 mb-8`}>
              <div>Admin</div>
              {adminMenuItems.map(({ title, Icon, link }, idx) => (
                <NavLink to={link} activeStyle={activeLink} key={idx}>
                  <MenuButton>
                    <div css={tw`flex items-center`}>
                      <Icon css={tw`mr-6`} />
                      <div>{title}</div>
                    </div>
                  </MenuButton>
                </NavLink>
              ))}
            </div>
          )}

          <MenuButton
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            <div css={tw`flex items-center`}>
              <IconLogout css={tw`mr-6`} />
              <div>Log out</div>
            </div>
          </MenuButton>
        </div>
      </div>
      <MobileMenu isAdmin={data && data.users[0].admin}/>
    </React.Fragment>
  );
}

export function SimpleMenu() {
  const location = useLocation();
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <div
      css={[
        tw`fixed w-full z-50 h-20 px-4 shadow-xs border-gray-300`,
        {
          backgroundColor: location.pathname == "/" ? "#F8DFDF" : "#fff",
        },
      ]}
    >
      <div css={tw`px-4 h-20 flex items-center md:px-20 w-full`}>
        <a href="/">
          <img src={logo} alt="beautiful lives boutique volunteer app" />
        </a>
        <div css={tw`ml-4 hidden md:block`}>
          Beautiful Lives Boutique Volunteer App
        </div>
        <div css={tw`ml-auto`}>
          {isAuthenticated ? (
            <button
              type="button"
              css={tw`py-3 px-6 rounded-md font-bold border border-black text-sm md:text-base`}
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              Log Out
            </button>
          ) : (
            <button
              type="button"
              css={tw`py-3 px-6 rounded-md font-bold border border-black text-sm md:text-base`}
              onClick={() =>
                loginWithRedirect({
                  redirectUri: window.location.origin + "/dashboard",
                })
              }
            >
              Log In
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function MobileMenu({ navigationElements, isAdmin, allowSchedule }) {
  const { logout, user } = useAuth0();

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const handleMenuClick = () => setMobileMenuVisible(!mobileMenuVisible);
  return (
    <div css={tw`lg:hidden`}>
      <div
        css={[tw`flex justify-between fixed w-full bg-white z-50 items-center px-8 h-20`, {backgroundColor: '#F8DFDF'}]}
      >
        <div>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div>
          {
            mobileMenuVisible ? <img onClick={handleMenuClick} src={close} alt="" /> : <img onClick={handleMenuClick} src={bars} alt="" />
          }
        </div>
      </div>
      {mobileMenuVisible && (
        <nav
          css={[tw`lg:hidden py-32 w-screen h-screen overflow-y-scroll fixed z-40 bg-white px-6`]}
        >
          <ul>
            {
              <div>
                {menuItems.map(({ title, Icon, link }, idx) => (
                  <NavLink to={link} activeStyle={activeLink} key={idx}>
                    <MenuButton onClick={(e) => handleMenuClick(e)}>
                      <div css={tw`flex items-center`}>
                        <Icon css={tw`mr-6`} />
                        <div>{title}</div>
                      </div>
                    </MenuButton>
                  </NavLink>
                ))}
                {isAdmin && (
                  <div css={tw`mt-8 mb-8`}>
                    <div>Admin</div>
                    {adminMenuItems.map(({ title, Icon, link }, idx) => (
                      <NavLink to={link} activeStyle={activeLink} key={idx}>
                        <MenuButton onClick={(e) => handleMenuClick(e)}>
                          <div css={tw`flex items-center`}>
                            <Icon css={tw`mr-6`} />
                            <div>{title}</div>
                          </div>
                        </MenuButton>
                      </NavLink>
                    ))}
                  </div>
                )}

                <MenuButton
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  <div css={tw`flex items-center`}>
                    <IconLogout css={tw`mr-6`} />
                    <div>Log out</div>
                  </div>
                </MenuButton>
              </div>
            }
          </ul>
        </nav>
      )}
    </div>
  );
}

export default Menu;