import React from "react";
import tw from "twin.macro";

import styled from "@emotion/styled";

/** @jsx jsx */
import { jsx } from "@emotion/core";

export const Input = styled.input(
  tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`,
  {
    height: 48,
    borderColor: "#C4C4C4",
  }
);

export const InputContainer = styled.div(tw`relative py-2 w-full`);

export const SelectButtonWrapper = styled.button(
  tw`p-2 bg-blb-gray-300 rounded-lg w-full text-center mr-2 lg:mr-4 focus:outline-none mb-4 text-lg appearance-none relative`,
  {
    height: 48,
    borderColor: "#C4C4C4",
    "& span": {
      pointerEvents: "none",
    },
  },
  (props) =>
    props.active && {
      backgroundColor: "#F8DFDF",
      border: "0",
      top: 5,
      right: -5,
      "& span": {
        position: "relative",
        top: -5,
        left: -5,
      },
      "&::after": {
        content: "' '",
        display: "block",
        position: "absolute",
        border: "2px solid #333",
        width: "100%",
        height: "100%",
        top: "-5px",
        left: "-5px",
        borderRadius: 8,
      },
    }
);

export const SelectButton = ({ children, ...props }) => (
  <SelectButtonWrapper {...props}>
    <span>{children}</span>
  </SelectButtonWrapper>
);

export const Label = styled.label(
  tw`text-lg font-medium pb-2 block`
);

export const NonInputLabel = styled.label(tw`mb-2 block`);

export const SubmitButton = styled.button(
  tw`w-full md:w-auto py-3 text-center rounded-lg font-semibold text-lg mb-8 text-white px-6 ml-auto bg-gmb-300`,
);

export const FormContainer = styled.form(tw`max-w-lg bg-white`);

export default FormContainer;
