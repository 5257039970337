import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import tw from "twin.macro";
import { SubmitButton, SelectButton } from "./Form";
import Loading from "./Loading";
import { ReactComponent as IconEdit } from "./icon-edit-pencil.svg";
import styled from "@emotion/styled";
import { Formik, Field, Form } from 'formik';


/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "./Account";

export const GET_CMS = gql`
  query GetCms{
    cms {
      id
      name
      content
      location {
        id
        name
      }
    }
    locations(order_by: { name: asc }) {
      id
      name
    }
  }
`;

const UPDATE_CMS = gql`
  mutation UpdateTodo(
    $id: Int!
    $content: jsonb!
  ) {
    update_cms(
      where: { id: { _eq: $id } }
      _set: {
        content: $content
      }
    ) {
      affected_rows
    }
  }
`;

function AdminCms() {
  const [locationFilter, setLocationFilter] = useState("Bentonville");
  const { loading, error, data: {cms, locations} = {} } = useQuery(GET_CMS);
  const [updateCMS] = useMutation(UPDATE_CMS, {
    onCompleted(data) {
      alert('content saved.')
    },
  });
  if (loading) return <Loading />;
  const handleLocationFilterClick = (val) => setLocationFilter(val);

  const contentData = cms.filter(obj => obj.location.name == locationFilter);
  const notifictionContent = contentData.find(elem => elem.name == 'notification')
  const socialEvents = contentData.find(elem => elem.name == 'socialEvents')
  const contentBlock = contentData.find(elem => elem.name == 'contentBlock')
  const pdfLinks = cms.find(elem => elem.name == 'pdfLinks')
  const orientationUrls = contentData.find(elem => elem.name == 'orientationUrl')

  const handleCMSChangeSubmit = (vals, cmsID) => {
    console.log(cmsID, vals);
    updateCMS({
      variables: {
        id: cmsID,
        content: [
          {
            ...vals
          }
        ]
      },
    });
  }

  return (
    <div>
      <div>
        <div css={tw`md:flex`}>
          {locations.map(({ name }, idx) => (
            <div key={idx} css={[tw`pr-4`]}>
              <SelectButton
                onClick={(evt) => handleLocationFilterClick(name)}
                active={name == locationFilter}
              >
                {name}
              </SelectButton>
            </div>
          ))}
        </div>
      </div>
      <div css={tw`mb-8`}>
      <Heading headline="Notification"/>
      <Formik
      initialValues={{
        content: notifictionContent.content[0].content,

      }}
      enableReinitialize
      onSubmit={(values) => {
        handleCMSChangeSubmit(values, notifictionContent.id)
      }}
    >
      <Form>
        <label css={tw`block`} htmlFor="firstName">Notification Content: </label>
        <Field id="content" name="content" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>
        <SubmitButton type="submit">Update</SubmitButton>
      </Form>
    </Formik>
    </div>
    <div css={tw`mb-8`}>
    <Heading headline="Events"/>
      <Formik
        enableReinitialize
        initialValues={{
          title: socialEvents.content[0].title,
          date: socialEvents.content[0].date,
          link: socialEvents.content[0].link,
          titleTwo: socialEvents.content[0].titleTwo || "",
          dateTwo: socialEvents.content[0].dateTwo || "",
          linkTwo: socialEvents.content[0].linkTwo || ""
        }}
        onSubmit={(values) => {
          handleCMSChangeSubmit(values, socialEvents.id)
        }}
    >
      <Form>
        <label css={tw`block`} htmlFor="firstName">Event Title: </label>
        <Field id="title" name="title" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="firstName">Event Date: </label>
        <Field id="date" name="date" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="firstName">Url to event page: </label>
        <Field id="link" name="link" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block mt-6`} htmlFor="firstName">Second Event Title: </label>
        <Field id="titleTwo" name="titleTwo" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="firstName">Second Event Date: </label>
        <Field id="dateTwo" name="dateTwo" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="firstName">Second Url to event page: </label>
        <Field id="linkTwo" name="linkTwo" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>
        <SubmitButton type="submit">Update</SubmitButton>
      </Form>
    </Formik>
    </div>
    <div css={tw`mb-8`}>
    <Heading headline="Content Block"/>
    <Formik
      enableReinitialize
      initialValues={{
        headline: contentBlock.content[0].headline,
        preHeadline: contentBlock.content[0].preHeadline,
        imageUrl: contentBlock.content[0].imageUrl,
        content: contentBlock.content[0].content,
        ctaLabel: contentBlock.content[0].ctaLabel ? contentBlock.content[0].ctaLabel : '',
        ctaUrl: contentBlock.content[0].ctaUrl ? contentBlock.content[0].ctaUrl : ''
      }}
      onSubmit={(values) => {
        console.log(values);
        handleCMSChangeSubmit(values, contentBlock.id)
      }}
    >
      <Form>
        <label css={tw`block`} htmlFor="preHeadline">Pre Title: </label>
        <Field id="preHeadline" name="preHeadline" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="headline">Title: </label>
        <Field id="headline" name="headline" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="imageUrl">Image Url: </label>
        <Field id="imageUrl" name="imageUrl" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent`}/>

        <label css={tw`block`} htmlFor="imageUrl">Content: </label>
        <Field as="textarea" id="content" name="content" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block`} htmlFor="imageUrl">CTA Label: </label>
        <Field id="ctaLabel" name="ctaLabel" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block`} htmlFor="imageUrl">CTA Url: </label>
        <Field id="ctaUrl" name="ctaUrl" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>
        <SubmitButton type="submit">Update</SubmitButton>
      </Form>
    </Formik>
    </div>
    <div css={tw`mb-8`}>
    <Heading headline="PDF Links"/>
    <Formik
      enableReinitialize
      initialValues={
        {
          credit: pdfLinks.content[0].credit,
          volunteer: pdfLinks.content[0].volunteer,
          faith: pdfLinks.content[0].faith,
          minor: pdfLinks.content[0].minor,
        }
      }
      onSubmit={(values) => {
        handleCMSChangeSubmit(values, pdfLinks.id)
      }}
    >
      <Form>
        <label css={tw`block`} htmlFor="credit">Credit Policy Link: </label>
        <Field id="credit" name="credit" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block`} htmlFor="volunteer">Volunteer Consent Form Link: </label>
        <Field id="volunteer" name="volunteer" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block`} htmlFor="faith">Statement of Faith Link: </label>
        <Field id="faith" name="faith" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <label css={tw`block`} htmlFor="minor">Minor Consent Form Link: </label>
        <Field id="minor" name="minor" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <SubmitButton type="submit">Update</SubmitButton>
      </Form>
    </Formik>
    </div>
    <div css={tw`mb-8`}>
    <Heading headline="Orientation Url"/>
    <Formik
      enableReinitialize
      initialValues={
        {
          orientationUrl: orientationUrls.content[0].orientationUrl,
        }
      }
      onSubmit={(values) => {
        handleCMSChangeSubmit(values, orientationUrls.id)
      }}
    >
      <Form>
        <label css={tw`block`} htmlFor="credit">Url where users can sign up for orienation</label>
        <Field id="orientationUrl" name="orientationUrl" placeholder="" css={tw`py-3 px-5 rounded-lg border-2 w-full focus:border-blb-pink-300 focus:outline-none appearance-none bg-transparent mb-4`}/>

        <SubmitButton type="submit">Update</SubmitButton>
      </Form>
    </Formik>
    </div>
    </div>
    
  )
}

export default AdminCms;