import React, { useEffect, useRef } from "react";
import tw from "twin.macro";
import { useAuth0 } from "@auth0/auth0-react";

import Loading from "./Loading";

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { gql, useQuery } from "@apollo/client";


export const USER_INFO = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      first_name
      last_name
      avatar
      admin
      schedule_allow
    }
  }
`;

function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }

function Profile(props) {
    useTraceUpdate(props);

  const { user } = useAuth0();
  const { loading, error, data } = useQuery(USER_INFO, {
    variables: { id: user.sub },
  });

  if(error) {
      console.log(error);
  }

  if (loading) return <Loading />;

  return (
      <React.Fragment
      >
        <div
          css={tw`uppercase w-16 h-16 flex items-center justify-center rounded-full bg-white mx-auto border-2 border-blb-gray-400 text-blb-gray-400 font-semibold`}
        >
          { data && data.users[0].first_name && `${data.users[0].first_name[0]} ${data.users[0].last_name[0]}`}
        </div>
        <div css={tw`capitalize text-center`}>
          {data && data.users[0].first_name && `${data.users[0].first_name} ${data.users[0].last_name}`}
        </div>

    </React.Fragment>
  );
}

export default Profile