import React, { useReducer, useState, useEffect } from "react";
import tw from "twin.macro";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import Loading from "./Loading";
import { format, parseISO } from "date-fns";

import { useRouteMatch, useHistory } from "react-router-dom";
import BreadCrumbs from "./Breadcrumbs";


/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "./Account";

export const CREDITS_INFO = gql`
  query($userId: String!) {
    transactions(where: { user_id: { _eq: $userId } }, order_by: {id: desc}) {
      created_at
      amount
      id
      type
    }
  }
`;

export const iconMap = {
  credit: '💵',
  purchase: '🛍',
  donation: '🥳',
  admin: '📝'
}

function TransactionHistory() {
  const { user } = useAuth0();

  let { path, url } = useRouteMatch();

  let [a, ...breadCrumbs] = path.split("/");
  const { loading, error, data } = useQuery(CREDITS_INFO, {
    variables: {
      userId: user.sub,
    },
  });

  if (loading) return <Loading />;

  if (error) {
    console.log(error);
  }

  return (
    <div>
      <BreadCrumbs breadcrumbs={breadCrumbs} />
      <Heading  headline="Transaction History"/>
      <div>
        <div css={tw`mt-4 max-w-xs`}>
            {
              data.transactions.map(({amount, created_at, id, type}) => (
                <div key={id} css={tw`flex`}>
                  <div>
                    <div css={tw`mr-2`}>
                    {`${iconMap[type]}`}
                    </div>
                  </div>
                  <div css={tw`mb-4`}>
                    <div css={tw`mr-4`}>{format(parseISO(created_at), "LLLL do, yyyy")}</div>
                    <div css={tw`text-xs capitalize p-1 rounded inline-block leading-none bg-gray-200`}>{type}</div>
                  </div>
                  <div css={tw`ml-auto font-bold`}>
                    {`${type == 'credit' ? '+': ''}${amount}`}
                  </div>
                </div>
              ))
            }
          </div>
      </div>
    </div>
  );
}


export default TransactionHistory;
