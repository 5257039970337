import React, { useState } from 'react'


/** @jsx jsx */
import { jsx } from "@emotion/core";
import PersonalInfo from './PersonalInfo';
import { Redirect } from 'react-router-dom';

function UserRegistration() {
    const [isUserRegistered, setIsUserRegistered] = useState(false)
    const [location, setLocation] = useState("")

    const handleUserRegisterChange = (val, loc) => {
        setLocation(loc)
        setIsUserRegistered(val)
    }
    return(
        <div>
            {
                isUserRegistered ? <Redirect to={{pathname:"/registration/volunteer-preferences", state: {location: location}}}  /> : <PersonalInfo  onUserRegisterChange={handleUserRegisterChange}/>
            }
        </div>
    )
}

export default UserRegistration