import React, { useReducer, useEffect, useState } from "react";
import tw from "twin.macro";
import Menu, { SimpleMenu } from "./MenuBar";
import ScrollToTop from "./ScrollToTop";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

export const Container = styled.div(tw`px-4 max-w-screen-lg mx-auto`);
function Layout({ children, simpleMenu }) {
  return (
    <div css={tw`md:flex`}>
      <div css={tw`md:min-h-full`}>
        {simpleMenu ? <SimpleMenu /> : <Menu />}
      </div>
      <main css={[tw`md:flex-1 md:min-h-screen`]}>
        {simpleMenu ? (
          <div
            css={tw`px-4 flex flex-col items-center pt-20 max-w-screen-lg mx-auto`}
          >
            <div css={tw`w-full`}>
              <ScrollToTop />
              {children}
            </div>
          </div>
        ) : (
          <div
            css={tw`px-4 flex flex-col items-center pt-20 max-w-screen-lg lg:pl-8`}
          >
            <div css={tw`w-full`}>
              <ScrollToTop />
              {children}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Layout;
