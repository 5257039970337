import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import eventPic from './icon-event.svg'
import handHeart from './icon-heart-hand-plain.svg'
import facebook from './facebook.png'
import instagram from './instagram.png'
import PartnerAcordian from "./Partners";

import tw from "twin.macro";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "./Account";
import { Link } from "react-router-dom";
import { ReactComponent as HeartCalIcon } from "./icon-hand-heart-cal.svg";

export const GET_CMS = gql`
  query ($id: String!){
    users(where: { id: { _eq: $id } }) {
      locations {
        name
        cms {
          name
          content
        }
        instagram_url
        facebook_url
      }
    }
    creditTotal: transactions_aggregate(where: { user_id: { _eq: $id } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
    donationTotal: transactions_aggregate(where: {user_id: { _eq: $id }, type: { _eq: "donation" } }) {
        aggregate {
          sum {
            amount
          }
        }
    }
  }
`;

function UserDashboard() {
  const { user } = useAuth0();
  let { loading, error, data } = useQuery(GET_CMS, {
    variables: { id: user.sub },
  });

  if(error) {
    return (
      <div>having issues getting data, please try again later. </div>
    )
  }

  if (loading) return <Loading />;
  console.log(data);

  const contentData = data?.users[0]?.locations.cms;
  const notifictionContent = contentData.find(elem => elem.name == 'notification')
  const socialEvents = contentData.find(elem => elem.name == 'socialEvents')
  const contentBlock = contentData.find(elem => elem.name == 'contentBlock')
  
  return (
    <div css={tw`max-w-3xl`}>
      <div css={[tw`md:hidden`,{
        backgroundColor: '#F8DFDF',
        height: '500px',
        width: '500px',
        position: 'fixed',
        transform: 'rotate(9deg)',
        borderRadius: '20px',
        zIndex: '-1',
        top: '-320px'
      }]}>
        test
      </div>
      <Heading headline="Welcome" />
      <div css={tw`flex`}>
        <div css={tw`p-4 bg-gray-300 rounded-lg mb-8 md:mb-10 w-full font-bold`}>
          {notifictionContent.content[0].content}
        </div>
      </div>
      <div>
        <a href={socialEvents.content[0].link}>
          <div css={tw`rounded-lg bg-white shadow-md p-4 flex items-center mb-8 md:mb-10`}>
            <div>
              <div>{socialEvents.content[0].date}</div>
              <div css={tw`font-bold text-2xl`}>{socialEvents.content[0].title}</div>
          </div>
          <div css={tw`ml-auto`}>
              <div css={[tw`p-4 flex justify-center items-center rounded-full`, {backgroundColor: '#BEE3F8', width: '90px', height: '90px'}]}>
                <img css={tw`w-full`} src={eventPic} />
              </div>
          </div>
          </div>
        </a>
      </div>
      {
        socialEvents.content[0].titleTwo && (
          <div>
            <a href={socialEvents.content[0].linkTwo}>
              <div css={tw`rounded-lg bg-white shadow-md p-4 flex items-center mb-8 md:mb-10`}>
                <div>
                  <div>{socialEvents.content[0].dateTwo}</div>
                  <div css={tw`font-bold text-2xl`}>{socialEvents.content[0].titleTwo}</div>
              </div>
              <div css={tw`ml-auto`}>
                  <div css={[tw`p-4 flex justify-center items-center rounded-full`, {backgroundColor: '#BEE3F8', width: '90px', height: '90px'}]}>
                    <img css={tw`w-full`} src={eventPic} />
                  </div>
              </div>
              </div>
            </a>
        </div>
        )
      }

      {
        data.creditTotal.aggregate.sum.amount ? 
          (
            <div>
              <Link
                    to="/credit" >
                <div css={tw`rounded-lg bg-white shadow-md p-4 flex items-center mb-8 md:mb-10`}>
                
                  <div>
                    <div css={tw`mb-2`}>
                      <div css={tw`font-bold text-xl`}>{data.creditTotal.aggregate.sum.amount}</div>
                      <div> Credit</div>
                    </div>
                    {
                      data.donationTotal.aggregate.amount ? (
                        <div>
                          <div css={tw`font-bold text-xl`}>{data.donationTotal.aggregate.sum.amount.replace(/-/, '')}</div>
                          <div> Credit Donated</div>
                        </div>
                      ) : ''
                    }
                    
                  </div>
                  <div css={tw`ml-auto`}>
                      <div css={[tw`p-4 flex justify-center items-center rounded-full`, {backgroundColor: '#D1E7D8', width: '90px', height: '90px'}]}>
                        <img css={tw`w-full`} src={handHeart} />
                      </div>
                  </div>
                </div>
                </Link>
            </div>
          ): ''
      }
      <div>
        <div
          css={[
            tw`p-6 text-center mt-6 max-w-md md:hidden mb-8 md:mb-10`,
            { backgroundColor: "#F1F3F4", borderRadius: 15 },
          ]}
        >
          <div css={tw`mb-8 md:mb-10`}>
            <HeartCalIcon css={tw`mx-auto`}/>
          </div>
          <div css={tw`font-bold mb-4`}>
            Contribute to the cause and earn some store credit.
          </div>
          <div css={tw`text-sm`}>
            Click below to start an in-store volunteering shift.
          </div>
          <Link
            to="/timer"
            css={tw`w-full block md:w-auto py-3 text-center rounded-lg font-semibold text-lg text-white px-6 ml-auto bg-gmb-300 mt-4`}
          >
            Start Shift
          </Link>
        </div>
      </div>
      <div>
        <div css={tw`text-xl mb-4`}>Lets Get Social:</div>
        <div css={tw`flex mb-8 md:mb-10`}>
          <div>
          <a href={data?.users[0]?.locations.facebook_url} >
          <div css={[tw`rounded-xl p-4 pt-10 mr-2 text-center relative flex justify-center`, {backgroundColor: '#BEE3F8', maxWidth: '193px'}]}>
            <img css={[tw`absolute`, {top: '-20px', border: '4px solid white', borderRadius: '100%'}]} src={facebook} width="56"/>
            
              Visit our Facebook page for in the know info.
          </div>
          </a>
          </div>
        <div>
        <a href={data?.users[0]?.locations.instagram_url} >
          <div css={[tw`rounded-xl p-4 pt-10 ml-2 text-center relative flex justify-center`, {backgroundColor: '#FFC790', maxWidth: '193px'}]}>
            <img css={[tw`absolute`, {top: '-20px', border: '0px solid white', borderRadius: '100%'}]} src={instagram} width="56"/>
            
              Visit our Instagram page for in the know info.
          </div>
          </a>
        </div>
          
        </div>
      </div>
      <div>
        <div>{contentBlock.content[0].preHeadline}</div>
        <div css={tw`text-2xl font-bold mb-4`}>{contentBlock.content[0].headline}</div>
        <div>
          <img css={tw`w-full rounded-xl max-w-lg mb-4`} src={contentBlock.content[0].imageUrl} />
        </div>
        <div css={tw`mb-4`}>
          {contentBlock.content[0].content}
        </div>
        <div css={tw`mb-8 md:mb-10`}>
          <a css={tw`font-bold text-lg underline text-blb-blue-500`} href={contentBlock.content[0].ctaUrl}>{contentBlock.content[0].ctaLabel} </a>
        </div>
      </div>
      <div className="mt-6">
          <PartnerAcordian />
      </div>
    </div>
  );
}

export default UserDashboard;
