import React, { useState } from "react";

import tw from "twin.macro";

/** @jsx jsx */
import { jsx } from "@emotion/core";

function NotificationBox({ title, subTitle, cancelLabel, confirmationLabel, onCancel, onConfirmation, icon }) {
  const [disable, setDisable] = useState(false)
  const handleSubmit = () => {
    setDisable(true)
    onConfirmation()
  }
  return (
    <div css={[tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`, {backgroundColor: 'rgba(0,0,0,0.20)'}]}>
      <div>
        <div
          css={[
            tw`p-6 text-center max-w-md shadow-xl`,
            { backgroundColor: "#F1F3F4", borderRadius: 15 },
          ]}
        >
          <div css={tw`mb-8`}>
            <img src={icon} css={[tw`mx-auto`, { width: 60, height: 60 }]} />
          </div>
          <div css={tw`font-bold mb-4`}>{title}</div>
          <div css={tw`text-sm mb-4`}>{subTitle}</div>
          <div css={tw`flex`}>
            <div css={tw`w-1/2 pr-2`}>
              <div
                css={tw`bg-gray-400 py-2 text-center rounded-md`}
                onClick={(evt) => onCancel(false)}
              >
                {cancelLabel}
              </div>
            </div>
            <div css={tw`w-1/2 pl-2`}>
              <button
                css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white w-full`}
                onClick={(evt) => handleSubmit()}
                disabled={disable}
              >
                {confirmationLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationBox
