import React, { useState, useRef } from "react";
import tw from "twin.macro";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useTransition, animated, useSpring, useChain } from "react-spring";
import { formatISO, parse, getDay } from "date-fns";
import styled from "@emotion/styled";

import CompletionScreen from "./CompletionScreen";
import { Heading } from "./Account";
import { SubmitButton } from "./Form";
import CreateShiftCalendar from "./CreateShiftCalendar";
import ShiftCreationForm from "./ShiftCreationForm";
import { ReactComponent as IconClose } from "./close.svg";
import { SHIFTS } from "./UserShifts";
import { ReactComponent as IconVolunteer } from "./icon-volunteer.svg";
import useWindowSize from "./useWindowSize";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import UserShifts from "./UserShifts";
import Loading from "./Loading";

const INSERT_EVENT = gql`
  mutation InsertEvent(
    $dateStart: timestamptz!
    $dateEnd: timestamptz!
    $locationId: Int!
    $userId: String!
  ) {
    insert_events(
      objects: [
        {
          date_start: $dateStart
          date_end: $dateEnd
          location_id: $locationId
          user_id: $userId
        }
      ]
    ) {
      returning {
        id
        date_start
        date_end
        location_id
      }
    }
  }
`;

export const LOCATION_INFO = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      schedule_allow
      locations {
        name
        id
        allow_events
        start_time
        end_time
        contact_email
        no_schedule_dates
      }
    }
  }
`;

function Schedule() {
  const [showBottomModal, setShowBottomModal] = useState(false);
  const { user } = useAuth0();

  const handleFormClose = () => {
    document.body.classList.remove('modal-open')
    setShowBottomModal(false)};

  const handleModalOpenClick = () => {
    document.body.classList.add('modal-open');
    setShowBottomModal(true)
  }
  const size = useWindowSize();
  const springRef = useRef();
  const transRef = useRef();

  const transitions = useTransition(showBottomModal, null, {
    ref: transRef,
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const { bottom, opacity, ...rest } = useSpring({
    ref: springRef,
    from: { bottom: "-100%" },
    to: { bottom: showBottomModal ? "-0%" : "-100%" },
  });

  const { loading, error, data: locationInfo } = useQuery(LOCATION_INFO, {
    variables: { id: user.sub },
  });

  useChain(showBottomModal ? [transRef, springRef] : [springRef, transRef], [
    0,
    showBottomModal ? 0.1 : 0.2,
  ]);

  if (loading) return <Loading />;

  if (!locationInfo.users[0].schedule_allow) {
    return (
      <div>
        😬 Oops! It looks like you dont have the permissions to schedule yet. Please
        email {locationInfo.users[0].locations.contact_email}
      </div>
    );
  }

  const isLargerThanMobile = size.width > 1024;
  

  return (
    <div>
      <Heading headline="Schedule" />
      <div css={tw`md:flex`}>
        <div css={tw`md:w-1/2`}>
          <div>
            <UserShifts onStartAddShift={handleModalOpenClick} />
          </div>
        </div>
        <div css={tw`md:w-1/2`}>
          <div
            css={[
              tw`bg-blb-pink-200 text-center text-blb-gray-400 px-6 py-8 mx-auto`,
              { borderRadius: "1.5rem", maxWidth: 280 },
            ]}
          >
            <div>
              <IconVolunteer css={tw`mx-auto mb-4`} />
            </div>
            <div css={tw`text-2xl mb-4`}>Volunteering for Shifts</div>
            <div>
              Volunteers make it possible for us to offer hope and resources to
              women in NWA and around the world. Plus, you can earn in-store
              credit to donate to our nonprofit partners or have a little fun
              and update your wardrobe!
            </div>
          </div>
        </div>
      </div>

      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={{
                ...props,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.5)",
                zIndex: "999",
                display: "flex",
                alignItems: isLargerThanMobile ? "center" : "flex-end",
                justifyContent: isLargerThanMobile && "center",
              }}
            >
              <animated.div
                style={{
                  ...rest,
                  backgroundColor: "#fff",
                  borderTopRightRadius: isLargerThanMobile ? 24 : 40,
                  borderTopLeftRadius: isLargerThanMobile ? 24 : 40,
                  borderBottomRightRadius: isLargerThanMobile ? 24 : 0,
                  borderBottomLeftRadius: isLargerThanMobile ? 24 : 0,
                  height: 550,
                  width: isLargerThanMobile ? 500 : "100%",
                  position: "relative",
                  bottom: bottom,
                  padding: 24,
                }}
              >
                <div css={tw`flex mb-4`}>
                  <div css={tw`ml-auto`} onClick={() => handleFormClose()}>
                    <IconClose />
                  </div>
                </div>
                <ShiftForm onFormClose={handleFormClose} />
              </animated.div>
            </animated.div>
          )
      )}
    </div>
  );
}

const ShiftFormContainer = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "flex-end",
  zIndex: 999,
});

const ShiftFormContent = styled.div(tw`p-4`, {
  background: "white",
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
  height: 500,
  width: "100%",
});

function ShiftForm({ onFormClose }) {
  const [startDate, setStartDate] = useState(new Date());
  const [shiftTime, setShiftTime] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [toggle, setToggle] = useState(0);
  const [direction, setDirection] = useState(0);

  const transitions = useTransition(toggle, (p) => p, {
    initial: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
    },
    from: {
      opacity: 0,
      transform: direction ? "translate3d(-100%,0,0)" : "translate3d(100%,0,0)",
      position: "absolute",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
    },
    leave: {
      opacity: 0,
      transform: direction ? "translate3d(100%,0,0)" : "translate3d(-100%,0,0)",
    },
  });

  const screens = [
    () => <CreateShiftCalendar onDaySelect={handleDaySelect} blockedDates={locationInfo?.users[0]?.locations.no_schedule_dates}/>,
    () => (
      <ShiftCreationForm
        availableStartTime={availabilityStart}
        availableEndTime={availabilityEnd}
        startDate={startDate}
        onShiftSubmit={handleShiftSubmit}
        onBack={setToggle}
        onChangeDirection={setDirection}
      />
    ),
    () => (
      <CompletionScreen shiftStart={shiftTime.start} shiftEnd={shiftTime.end}>
        <SubmitButton onClick={() => onFormClose()}>Continue</SubmitButton>
      </CompletionScreen>
    ),
  ];

  const { user } = useAuth0();

  const [addEvent, { data }] = useMutation(INSERT_EVENT, {
    refetchQueries: [{ query: SHIFTS, variables: { id: user.sub } }],
    onCompleted(data) {
      setToggle(2);
    },
  });

  const { loading, error, data: locationInfo } = useQuery(LOCATION_INFO, {
    variables: { id: user.sub },
  });

  if (loading) return <Loading />;

  if (error) {
    console.log(error);
  }

  const { start_time, end_time, id: locationId } =
    (locationInfo && locationInfo?.users[0]?.locations) || {};

  const availabilityStart = parse(start_time, "HH:mm", startDate);

  const availabilityEnd = parse(end_time, "HH:mm", startDate);

  const handleDaySelect = (val) => {
    if (getDay(val) == 0) {
      return;
    }
    setDirection(0);
    setToggle(1);
    setStartDate(val);
  };

  const handleShiftSubmit = (shiftStart, shiftEnd) => {
    setShiftTime({ start: shiftStart, end: shiftEnd });

    addEvent({
      variables: {
        dateStart: formatISO(shiftStart),
        dateEnd: formatISO(shiftEnd),
        locationId: locationId,
        userId: user.sub,
      },
    });
  };

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        "& > div": {
          position: "absolute !important",
          width: "100%",
        },
      }}
    >
      {transitions.map(({ item, key, props }) => {
        const Screen = screens[item];
        return (
          <animated.div style={{ ...props, position: "absolute" }}>
            <Screen key={key} />
          </animated.div>
        );
      })}
    </div>
  );
}

export default Schedule;
