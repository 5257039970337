import React, { useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import tw from "twin.macro";
import {
  isBefore,
  isAfter,
  parseISO,
  format,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

import { ReactComponent as IconCalendar } from "./icon-calendar-blank.svg";
import { ReactComponent as IconTrash } from "./icon-trash.svg";
import { SubmitButton } from "./Form";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { animated, useTrail, useTransition } from "react-spring";

const SHIFTS = gql`
  query($id: String!) {
    events(where: { user_id: { _eq: $id } }, order_by: { date_start: asc }) {
      id
      date_start
      date_end
      location_id
      user_id
    }
  }
`;

const DELETE_SHIFT = gql`
  mutation deleteShift($id: Int!) {
    delete_events(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const PAST_SHIFTS = gql`
  query($id: String!) {
    time_sheets(where: { user_id: { _eq: $id } }, order_by: { from: asc }, limit: 20) {
      id
      from
      to
      duration_minutes
      user_id
    }
  }
`;

function UserShifts({ onStartAddShift }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState("");

  const { user } = useAuth0();
  const { loading, error, data } = useQuery(SHIFTS, {
    variables: { id: user.sub },
  });

  const {loading: pastShiftsLoading, data: pastShiftsData, error: errorPastsShifts} = useQuery(PAST_SHIFTS, {
    variables: { id: user.sub}
  })

  const [deleteShift] = useMutation(DELETE_SHIFT, {
    refetchQueries: [
      {
        query: SHIFTS,
        variables: { id: user.sub },
      },
    ],
  });

  if (loading || pastShiftsLoading) return <Loading />;
  
  if (error || errorPastsShifts) {
    console.log(error, errorPastsShifts);
  }

  const now = new Date();

  console.log(pastShiftsData);

  const pastShifts = data.events.filter(({ date_start }) =>
    isBefore(parseISO(date_start), now)
  );
  const upcomingShifts = data.events.filter(({ date_start }) =>
    isAfter(parseISO(date_start), now)
  );

  const handleDeleteShift = (evt) => {
    evt.stopPropagation();
    deleteShift({ variables: { id: selectedShift } });
    setShowModal(false);
  };

  return (
    <div>
      <div css={tw`text-xl font-bold mb-2`}>Shifts</div>
      {upcomingShifts.length > 0 ? (
        <div>
          <ul>
            <Trail>
              {upcomingShifts.map(({ id, date_start, date_end, user_id }) => (
                <li key={id}>
                  <Shift
                    dateStart={date_start}
                    dateEnd={date_end}
                    id={id}
                    userID={user_id}
                    onModalClick={setShowModal}
                    onSelectShift={setSelectedShift}
                  />
                </li>
              ))}
            </Trail>
          </ul>
        </div>
      ) : (
        <div css={tw`text-center mb-8`}>
          Oh no 😱! You don't have any upcoming shifts. Click the button below
          to schedule one.
        </div>
      )}
      <div>
        <SubmitButton onClick={() => onStartAddShift(true)}>
          Add Shift
        </SubmitButton>
      </div>
      {pastShiftsData.time_sheets.length > 0 && (
        <div css={tw`mt-8 mb-8`}>
          <div css={tw`text-xl font-bold mb-2`}>Past Shifts</div>
          <div>
            <div css={tw`flex mb-4 justify-between`}>
              <div css={tw`font-bold mr-32 `}>Date</div>
              <div css={tw`font-bold `}>Time</div>
              <div css={tw`font-bold ml-auto`}>Duration</div>
              </div>
            {pastShiftsData.time_sheets
              .map(({ id, from, to, duration_minutes }) => (
                <div key={id} css={tw`flex `}>
                  <div css={tw`mr-4 mb-4`}>{format(parseISO(from), "EEEE, LLLL do")}</div>
                  <div>{format(parseISO(from), "hh:mm aaa ")} - {format(parseISO(to), "hh:mm aaa ")}</div>
                  <div css={tw`ml-auto`}>{`${duration_minutes} mins`}</div>
                </div>
              ))}
          </div>
        </div>
      )}
      {showModal && (
        <DeleteConfirmation>
          <div css={tw`flex`}>
            <div css={tw`w-1/2 pr-2`}>
              <div
                css={tw`bg-gray-400 py-2 text-center rounded-md`}
                onClick={(evt) => setShowModal(false)}
              >
                Cancel
              </div>
            </div>
            <div css={tw`w-1/2 pl-2`}>
              <div
                css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white`}
                onClick={(evt) => handleDeleteShift(evt)}
              >
                Delete shift
              </div>
            </div>
          </div>
        </DeleteConfirmation>
      )}
    </div>
  );
}

function Shift({
  dateStart,
  dateEnd,
  id,
  userID,
  onModalClick,
  onSelectShift,
}) {
  const [trash, toggleTrash] = useState(false);

  const transitions = useTransition(trash, null, {
    unique: true,
    from: { opacity: 0, transform: "translate3d(0, 10px, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
    leave: { opacity: 0, transform: "translate3d(0, 10px, 0)" },
  });

  const handleCloseDeleteConf = () => {
    toggleTrash(false);
    onModalClick(false);
  };

  const handleSelectShift = () => {
    onSelectShift(id);
    onModalClick(true);
  };

  return (
    <div
      css={[
        tw`flex p-4 rounded-lg mb-4 items-center`,
        { backgroundColor: "#F1F3F4" },
      ]}
      onClick={(evt) => toggleTrash((state) => !state)}
    >
      <div css={tw`relative flex items-center justify-center mr-6`}>
        <IconCalendar />
        <div css={tw`absolute text-sm pt-2`}>
          {format(parseISO(dateStart), "d")}
        </div>
      </div>
      <div>
        <div css={tw`font-bold`}>
          {format(parseISO(dateStart), "EEEE, LLLL do")}
        </div>
        <div>{`${format(parseISO(dateStart), "h:mm")} - ${format(
          parseISO(dateEnd),
          "h:mm"
        )}`}</div>
      </div>

      <div css={tw` ml-auto flex`}>
      {trash && (
        <div css={tw`mr-4`} onClick={(e) => handleSelectShift()}>
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <div>
                    <IconTrash />
                  </div>
                </animated.div>
              )
          )}
        </div>
      )}
      <svg xmlns="http://www.w3.org/2000/svg" width='24' css={tw`rotate-45 ml-auto`} viewBox="0 0 448 512"><path d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"/></svg>
      </div>
    </div>
  );
}

function Trail({ children, ...props }) {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
  });

  return (
    <React.Fragment>
      {trail.map(({ x, ...rest }, index) => (
        <animated.div
          key={index}
          style={{
            ...rest,
            transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
          }}
        >
          {items[index]}
        </animated.div>
      ))}
    </React.Fragment>
  );
}

export function DeleteConfirmation({ children }) {
  return (
    <div css={[tw`fixed top-0 bottom-0 left-0 right-0 bottom-0 flex flex items-center justify-center`, {backgroundColor: 'rgba(0,0,0,0.20)'}]}>
      <div>
        <div
          css={[
            tw`p-6 text-center max-w-md shadow-xl`,
            { backgroundColor: "#F1F3F4", borderRadius: 15 },
          ]}
        >
          <div css={tw`mb-8`}>
            <IconTrash css={[tw`mx-auto`, { width: 60, height: 60 }]} />
          </div>
          <div css={tw`font-bold mb-4`}>
            Would you like to delete this shift?
          </div>
          <div css={tw`text-sm mb-4`}>
            This shift will be deleted and you cannot undo this action.
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default UserShifts;
export { SHIFTS };
