import React, { useReducer, useState, useEffect } from "react";
import tw from "twin.macro";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";

import { useRouteMatch, useHistory } from "react-router-dom";
import BreadCrumbs from "../Breadcrumbs";

import {
  InputContainer,
  SelectButton,
  NonInputLabel,
  Input,
  Label,
  SubmitButton,
  FormContainer,
} from "../Form";
import { sentenceCase } from "../utils";


/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "../Account";

const UPDATE_USER = gql`
  mutation UpdateTodo(
    $id: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $emergencyName: String!
    $emergencyPhoneNumber: String!
    $emergencyRelationship: String!
    $email: String!
    $birthdate: String!
    $preferredNotificationMethod: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        email: $email
        preferred_notification_method: $preferredNotificationMethod
        birthdate: $birthdate
        emergency_name: $emergencyName
        emergency_phone_number: $emergencyPhoneNumber
        emergency_relationship: $emergencyRelationship
      }
    ) {
      affected_rows
    }
  }
`;

export const USER_INFO = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      first_name
      last_name
      phone_number
      email
      preferred_notification_method
      birthdate
      emergency_name
      emergency_phone_number
      emergency_relationship
    }
  }
`;

function PersonalInfo() {
  let { path, url } = useRouteMatch();

  let [a, ...breadCrumbs] = path.split("/");
  console.log(breadCrumbs);
  return (
    <div>
      <BreadCrumbs breadcrumbs={breadCrumbs} />
      <Heading  headline="Personal Info"/>
      <div>
        <Form />
      </div>
    </div>
  );
}

function Form() {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      birthdate: "",
      emergencyFullName: "",
      emergencyPhoneNumber: "",
      emergencyRelationship: "",
      preferredNotificationMethod: "",
    }
  );

  const [inputActive, setInputActive] = useState(false);

  const { user } = useAuth0();

  const history = useHistory();

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      history.push("/account-settings");
    },
  });

  const { loading, error, data } = useQuery(USER_INFO, {
    variables: { id: user.sub },
  });

  useEffect(() => {
    let mounted = true;
    if (data) {
      const user = data.users[0];
      if (mounted) {
        setUserInput({
          birthdate: user.birthdate || "",
          emergencyFullName: user.emergency_name || "",
          emergencyPhoneNumber: user.emergency_phone_number || "",
          emergencyRelationship: user.emergency_relationship || "",
          firstName: user.first_name || "",
          lastName: user.last_name || "",
          phoneNumber: user.phone_number || "",
          email: user.email || "",
          preferredNotificationMethod: user.preferred_notification_method || "",
        });
      }
    }

    return () => (mounted = false);
  }, [data]);

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleSubmit = (evt) => {
    updateUser({
      variables: {
        id: user.sub,
        firstName: userInput.firstName,
        lastName: userInput.lastName,
        phoneNumber: userInput.phoneNumber,
        email: userInput.email,
        preferredNotificationMethod: userInput.preferredNotificationMethod,
        birthdate: userInput.birthdate,
        emergencyName: userInput.emergencyFullName,
        emergencyPhoneNumber: userInput.emergencyPhoneNumber,
        emergencyRelationship: userInput.emergencyRelationship,
      },
      optimisticResponse: true
    });
  };

  const handleInputActiveChange = (input) => setInputActive(input);

  const inputs = ["firstName", "lastName", "phoneNumber", "email"];
  const emergencyContactInputs = [
    { name: "emergencyFullName", label: "Full Name" },
    { name: "emergencyPhoneNumber", label: "Phone Number" },
    { name: "emergencyRelationship", label: "Relationship" },
  ];

  const notificationOptions = ["Text", "Email", "Call"];
  return (
    <div css={tw``}>
      <FormContainer
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit(evt);
        }}
      >
        <div css={tw`md:flex md:flex-wrap`}>
          {inputs.map((input, idx) => (
            <InputContainer key={idx}>
              <Label
                htmlFor={input}
                active={
                  Boolean(input === inputActive) || Boolean(userInput[input])
                }
              >
                {sentenceCase(input)}
              </Label>
              <Input
                name={input}
                type={input == "phoneNumber" ? "number" : "text"}
                value={userInput[input]}
                onChange={(evt) => handleInputChange(evt)}
                onClick={(evt) => handleInputActiveChange(input)}
                required
                disabled={input == 'email' ? true : false}
              />
            </InputContainer>
          ))}
        </div>
        <InputContainer>
          <Label htmlFor="birthdate">{"Birthdate"}</Label>
          <Input
            name={"birthdate"}
            type="date"
            value={userInput["birthdate"]}
            onChange={(evt) => handleInputChange(evt)}
            onClick={(evt) => handleInputActiveChange("birthdate")}
            placeholder="yyyy-mm-dd"
          />
          <span css={tw`text-xs pl-2`}>optional</span>
        </InputContainer>
        <div css={tw`pt-2`}>
          <NonInputLabel>Emergency Contact</NonInputLabel>
          {emergencyContactInputs.map(({ name, label }, idx) => (
            <InputContainer key={idx}>
              <Label
                htmlFor={name}
                active={
                  Boolean(name === inputActive) || Boolean(userInput[name])
                }
              >
                {label}
              </Label>
              <Input
                name={name}
                type="text"
                value={userInput[name]}
                onChange={(evt) => handleInputChange(evt)}
                onClick={(evt) => handleInputActiveChange(name)}
              />
            </InputContainer>
          ))}
        </div>

        <InputContainer>
          <Label>Preferred method of contact:</Label>
          <div css={tw`md:flex`}>
            {notificationOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="preferredNotificationMethod"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["preferredNotificationMethod"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        <InputContainer>
          <SubmitButton type="submit" onChange={(evt) => alert("submit")}>
            Save
          </SubmitButton>
        </InputContainer>
      </FormContainer>
    </div>
  );
}

export default PersonalInfo;
