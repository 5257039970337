import React from "react";
import tw from "twin.macro";
import { ReactComponent as IconHeart } from "./icon-heart.svg";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

function Footer() {
  return (
    <footer css={tw`bg-blb-gray-300 w-full py-6`}>
      <div css={tw`px-4 md:px-20 text-center md:text-left mx-auto text-sm md:flex justify-between`}>
        <div>
          &copy; 2020 Beautiful Lives Thrift Boutique, All rights reserved
        </div>
        <div>
          Made with{"  "}
          <span>
            <IconHeart css={tw`inline-block`} />
          </span>{"  "}
          by RenderCake
        </div>
      </div>
    </footer>
  );
}

export default Footer;
