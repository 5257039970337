import React, { useState } from "react";
import Calendar from "react-calendar";
import styled from "@emotion/styled";
import { format } from 'date-fns'



/** @jsx jsx */
import { jsx } from "@emotion/core";

const CalendarContainer = styled.div({
    "& .react-calendar__navigation": {
      marginBottom: "1rem",
    },
    "& .react-calendar__navigation__label": {
      textAlign: "left",
    },
    "& .react-calendar__navigation__label__labelText": {
      fontSize: 30,
    },
    "& .react-calendar__month-view__weekdays__weekday": {
      textAlign: "center",
      "& abbr[title]": {
        textDecoration: "none",
      },
    },
    "& .react-calendar__tile": {
      padding: "1rem 0",
    },
    "& .react-calendar__tile[disabled]": {
      color: "#a0aec0",
    },
    "& .react-calendar__tile--active": {
      background: "#333",
      borderRadius: 8,
      color: "white",
      "&:focus": {
        outline: "none",
      },
    },
  });

function CreateShiftCalendar({onDaySelect, blockedDates}) {
  const [startDate, setStartDate] =useState(new Date())
  const handleDaySelect = (val) => onDaySelect(val);
  const today = new Date()

  return (
    <div>
      <div>Select Day</div>
      <CalendarContainer>
        <Calendar
          onChange={(val) => handleDaySelect(val)}
          prev2Label={null}
          next2Label={null}
          defaultValue={today}
          minDate={today}
          tileDisabled={({activeStartDate, date, view }) => {
            const formatedDay = format(date, 'y-MM-dd')
            console.log(formatedDay);
            return blockedDates.split(",").includes(formatedDay)
          }}
        />
      </CalendarContainer>
    </div>
  );
}

export default CreateShiftCalendar;
