import React, {  useState } from "react";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import tw from "twin.macro";
import { SubmitButton, SelectButton } from "./Form";
import Loading from "./Loading";
import styled from "@emotion/styled";
import loadingIcon from "./loading.svg";
import { CSVLink } from "react-csv";
import {ReactComponent as IconDownload} from './icon-export.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  InputContainer,
  Input,
  Label,
} from "./Form";



/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  format,
  isValid,
  parseISO,
  differenceInMinutes,
  formatISO,
  subDays,
  isAfter,
  set,
} from "date-fns";
import { da, fi } from "date-fns/locale";
import { useError, useRaf } from "react-use";

export const GET_SHIFTS = gql`
  query GetShifts($dateStart: timestamptz! $dateEnd: timestamptz! $locations: [String!]) {
    time_sheets_aggregate(where:{ _and:[ {from: {_gt: $dateStart}}, {from: {_lt: $dateEnd}}], user:{location: {_in: $locations}}}) {
      aggregate {
        sum { 
          duration_minutes
        }
      }
    }
    time_sheets(where:{ _and:[ {from: {_gt: $dateStart}}, {from: {_lt: $dateEnd}}], user:{location: {_in: $locations}}}) {
      duration_minutes
      from
      to
      user {
        email
        location
        id
        first_name
        last_name
      }
    }
    locations(order_by: { name: asc }) {
      id
      name
    }
  }
`;


export const GET_NEW_VOLUNTEERS = gql`
  query GetNewVolunteers($users: [String!]) {
    users(where: {id: {_in: $users}}) {
      email
      location
      time_sheets_aggregate {
        aggregate {
            min {
              from
          }
        }
      }
    }
  }
`;

const DownloadCSV = SubmitButton.withComponent(CSVLink);

const reportLabels = [{
  name: "totalVolunteerHrs",
  label: 'Volunteering Hours',
  headers: [
    {
      label: 'duration', key: 'duration_minutes',
    },
    {
      label: 'email', key: 'user.email'
    },
    {
      label: 'first name', key: 'user.first_name'
    },
    {
      label: 'last name', key: 'user.last_name'
    },
    {
      label: 'location', key: 'user.location'
    }
  ]
},
{
  name: "uniqueVolunteers",
  label: 'Unique Volunteers',
  headers: [
    {
      label: 'email', key: 'user.email'
    },
    {
      label: 'location', key: 'user.location'
    }
  ]
},
{
  name: "firstTimeVolunteers",
  label: 'First Time Volunteers',
  headers: [
    {
      label: 'email', key: 'email'
    },
    {
      label: 'location', key: 'location'
    },
    {
      label: 'first volunteer date', key: 'time_sheets_aggregate.aggregate.min.from'
    }
  ]
}]



function AdminReports() {
  const d = new Date();
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const [locationFilter, setLocationFilter] = useState("All");
  const [reportFilter, setReportFilter] = useState('totalVolunteerHrs')
  const [volunteeredUsers, setVolunteeredUsers] = useState([""])
  const [minHours, setMinHours] = useState(0)

  let { loading, error, data: {time_sheets_aggregate, locations, time_sheets} = {} } = useQuery(GET_SHIFTS, {
    variables: {
      dateStart: startDate,
      dateEnd: endDate,
      locations: locationFilter === 'All' ? ['Bentonville', 'Fayetteville', 'Siloam Springs'] : [locationFilter]
    }
  });

  let {loading: userloading, error: userError, data} = useQuery(GET_NEW_VOLUNTEERS, {
    variables: {
      users: volunteeredUsers
    }
  })

  if (loading || userloading) return <Loading />;


  locations = [{
    id: 0,
name: "All",
__typename: "locations"
  }, ...locations]


  const uniqueVolunteers = time_sheets.reduce((unique, item) => unique.includes(item.user.id) ? unique : [...unique, item.user.id] , [])
  const firstTimeVolunteers = data.users.filter((user) => isAfter(new Date(user.time_sheets_aggregate.aggregate.min.from), startDate))

  const handleLocationFilterClick = (val) => setLocationFilter(val);
  const handleReportsFilterClick = (val) => {
    setReportFilter(val)
    if( val === "firstTimeVolunteers") {
      setVolunteeredUsers(uniqueVolunteers)
    }
  }


  const combinedData = time_sheets.reduce((acc, curr) => {
    let userIndex = acc.findIndex((elem) => elem.user.email === curr.user.email)
    if(userIndex > -1) {
      const accDuration = curr.duration_minutes + acc[userIndex].duration_minutes
      console.log(acc[userIndex].user ,acc[userIndex].duration_minutes);
      acc[userIndex] = {...acc[userIndex], duration_minutes: accDuration } 
    } else {
      acc.push(curr)
    }
    return acc
  }, [])

  const filteredData = combinedData.filter((elem) => minHours > 0 ? elem.duration_minutes > minHours * 60 : true)



  return (
    <div>
      <h1 css={tw`text-gray-900 text-2xl font-bold mb-2`}>Admin Reports</h1>
      <div css={tw`mb-8`}>
        <div css={tw`md:flex`}>
          {reportLabels.map(({ name, label }, idx) => (
            <div key={idx} css={[tw`pr-4`]}>
              <SelectButton
                onClick={(evt) => handleReportsFilterClick(name)}
                active={name == reportFilter}
              >
                {label}
              </SelectButton>
            </div>
          ))}
        </div>
      </div>
      <div css={tw`flex`}>
        <CSVLink
          css={tw`block font-bold mb-4 text-center max-w-xs ml-auto flex mr-2`}
          headers={reportLabels.find(elem => elem.name === reportFilter).headers}
          data={reportFilter === "firstTimeVolunteers" ? data.users : filteredData}
          filename={`blb-volunteer-timeshifts-all-time-${d.getDate()}.${
            d.getMonth() + 1
          }.${d.getFullYear()}.csv`}
        >
          <span><IconDownload css={tw`mr-2`}/></span>
          Export
        </CSVLink>
      </div>
      <div>
      <div css={tw`text-xl font-bold mb-2`}>Filters</div>
      <div css={tw`mb-8`}>
        <div>Locations</div>
        <div css={tw`md:flex`}>
            {locations.map(({ name }, idx) => (
              <div key={idx} css={[tw`pr-4`]}>
                <SelectButton
                  onClick={(evt) => handleLocationFilterClick(name)}
                  active={name == locationFilter}
                >
                  {name}
                </SelectButton>
              </div>
            ))}
          </div>
      </div>
      <div css={tw`mb-8`}>
      <div>Time Range</div>
      <div css={tw`flex`}>
      <div>
        Start Date:
      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
      </div>
      <div>
        End Date:
      <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
      </div>
      </div>
      {
          reportFilter === "totalVolunteerHrs" && (
            <div css={tw`w-40`}>
              <div>Set minimum hours worked</div>
            <Input
                name="minHours"
                type="number"
                value={minHours}
                onChange={(evt) => setMinHours(evt.target.value)}
              />

            </div>
          )
        }



      </div>
      <div>
        <div css={tw`text-xl font-bold mb-2`}>Results</div>
        {
          reportFilter === "totalVolunteerHrs" && (
            <div>
            {
              time_sheets_aggregate.aggregate.sum.duration_minutes ? <div>{Math.round(time_sheets_aggregate.aggregate.sum.duration_minutes /60)} hrs</div> : <div> no data </div>
            }
          </div>
          )
        }
        {
          reportFilter === "uniqueVolunteers" && (
            <div>
              {
                uniqueVolunteers.length
              }
            </div>
          )
        }
                {
          reportFilter === "firstTimeVolunteers" && (
            <div>
              {
                firstTimeVolunteers.length
              }
            </div>
          )
        }

      </div>
    </div>
  )
}



export default AdminReports;
