import React from "react";
import tw from "twin.macro";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import CreditPdf from "../credit-policy.pdf";
import VolunteerConsentPdf from "../volConsent.pdf";
import StatementOfFaith from "../sof.pdf";
import MinorForm from "../minor-form.pdf";
import { useQuery, gql, useMutation } from "@apollo/client";
import Loading from "../Loading";




import { useRouteMatch, useHistory } from "react-router-dom";
import BreadCrumbs from "../Breadcrumbs";


/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "../Account";

export const GET_CMS = gql`
  query GetCms{
    cms(where: {name: { _eq:"pdfLinks"}}) {
    id
    content
    name
    }
  }
`;




function Policies() {
  let { path, url } = useRouteMatch();

  let [a, ...breadCrumbs] = path.split("/");
  const { loading, error, data = {} } = useQuery(GET_CMS);
  if (loading) return <Loading />;

  console.log(data);
  const {credit, minor, volunteer, faith} = data.cms[0].content[0]

  return (
    <div>
      <BreadCrumbs breadcrumbs={breadCrumbs} />
      <Heading  headline="Policies and Documents"/>
      <div css={tw``}>
        {
          credit && (
            <a css={tw`block text-blue-900 underline mb-4`} href={credit} target="_blank">
            Credit Policy
        </a>
          )
        }
        {
          volunteer && (
            <a css={tw`block text-blue-900 underline mb-4`} href={volunteer} target="_blank">
            Volunteer Consent Form
            </a>
          )
        }
        {
          faith && (
            <a css={tw`block text-blue-900 underline mb-4`} href={faith} target="_blank">
              Statement of Faith
            </a>
          )
        }
        {
          minor && (
            <a css={tw`block text-blue-900 underline mb-4`} href={minor} target="_blank">
              Minor Consent Form
            </a>
          )
        }
      </div>
    </div>
  );
}



export default Policies;
