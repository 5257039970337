import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import tw from "twin.macro";
import { useQuery, gql, useMutation } from "@apollo/client";
import { differenceInMinutes, differenceInHours, format, formatISO,setDate, getDate, isDate, parseISO, set } from "date-fns";
import Confetti from "react-dom-confetti";
import styled from "@emotion/styled";
import { ErrorMessage, Field, Form, Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";


import { Heading } from "./Account";
import { SelectButton, SubmitButton } from "./Form";
import { ReactComponent as IconSetting } from "./icon-settings.svg";
import { ReactComponent as IconClose } from "./close.svg";
import clock from "./icon-clock.svg";
import CreditPdf from "./credit-policy.pdf";
import VolunteerConsentPdf from "./volConsent.pdf";
import StatementOfFaith from "./sof.pdf";




/** @jsx jsx */
import { jsx } from "@emotion/core";
import Loading from "./Loading";
import LocationSettingsForm from "./LocationSettingsForm";
import NotificationBox from "./Notification";
import TimerEdit from "./TimerEdit";
import { id } from "date-fns/locale";

const INSERT_TIMER = gql`
  mutation StartTimer($startTime: timestamptz!, $userId: String!) {
    insert_time_sheets(objects: [{ from: $startTime, user_id: $userId }]) {
      returning {
        id
        from
        to
        user_id
      }
    }
  }
`;

export const INSERT_TRANSACTION = gql`
  mutation AddCredit($amount: money!, $userId: String!, $shiftId: Int!) {
    insert_transactions(
      objects: [
        {
          amount: $amount
          user_id: $userId
          shift_id: $shiftId
          type: "credit"
        }
      ]
    ) {
      returning {
        id
        amount
        type
        user_id
        shift_id
      }
    }
  }
`;

const END_TIMER = gql`
  mutation EndTime($id: Int!, $from: timestamptz, $to: timestamptz,  $durationMinutes: Int!) {
    update_time_sheets(
      where: { id: { _eq: $id } }
      _set: { to: $to, from: $from, duration_minutes:  $durationMinutes}
    ) {
      affected_rows
    }
  }
`;

const SET_SCHEDULE_ALLOW = gql`
  mutation EndTime($id: String!) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { schedule_allow: true}
    ) {
      affected_rows
    }
  }
`;

export const TIMER_INFO = gql`
  query($id: String!) {
    users(where: { id: { _eq: $id } }) {
      id
      credit_policy
      location
      schedule_allow
      time_sheets(where: { to: { _is_null: true } }) {
        id
        from
        to
        user_id
      }
      transactions_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
    cms(where: {name: { _eq:"pdfLinks"}}) {
      id
      content
      name
    }
  }
`;


const ClockButton = styled.button(
  tw`bg-blb-pink-200 rounded-full font-bold text-2xl shadow mx-auto block mt-8 mb-16 focus:outline-none active:bg-blb-pink-200 hover:bg-blb-pink-200 md:rounded-lg md:bg-gmb-300 md:text-white md:px-6 md:py-3 md:mx-0 md:text-lg md:hover:bg-gmb-300 md:hover:shadow-2xl`,
  {
    width: 200,
    height: 200,
    WebkitTapHighlightColor: "transparent",
    "@media screen and (min-width: 768px)": {
      width: "auto",
      height: "auto",
    },
  }
);

const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $photoConsent: String!) {
    update_users(where: { id: { _eq: $id } }, _set: { credit_policy: true, photo_consent: $photoConsent }) {
      affected_rows
    }
  }
`;

const UPDATE_FIRST_TIME_USER = gql`
  mutation UpdateUser($id: String!) {
    update_users(where: { id: { _eq: $id } }, _set: { schedule_allow: true }) {
      affected_rows
    }
  }
`;

const config = {
  angle: 90,
  spread: 150,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

function Timer() {
  const [active, setActive] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [credits, setCredits] = useState(0)
  const [clockedInTime, setClockedInTime] = useState(false);
  const [creditAmount, setCreditAmount] = useState(0); 
  const [clockedOutTime, setClockedOutTime] = useState(false);
  const [timerID, setTimerID] = useState(false);
  const [showTimerFix, setShowTimerFix] = useState(false);
  const [showTimerLoggedCongrats, setShowTimerLoggedCongrats] = useState(false);
  const [
    showTimerCompleteConfirmation,
    setShowTimerCompleteConfirmation,
  ] = useState(false);
  const { user } = useAuth0();

  const { error, loading, data } = useQuery(TIMER_INFO, {
    variables: {
      id: user.sub,
    },
  });

  useEffect(() => {
    let mounted = true;
    if(data?.users[0]?.transactions_aggregate.aggregate.sum.amount) {
      setCreditAmount(parseInt(data?.users[0]?.transactions_aggregate.aggregate.sum.amount.slice(1)))
    }
    if (data?.users[0]?.time_sheets[0]?.from) {
      if (mounted) {
        setClockedInTime(parseISO(data?.users[0]?.time_sheets[0].from));
        setTimerID(data?.users[0]?.time_sheets[0].id);
      }
    }

    return () => (mounted = false);
  }, [data]);

  const [startClock, { data: retData }] = useMutation(INSERT_TIMER, {
    onCompleted(data) {
      setShowNotification(false);
      setClockedInTime(parseISO(data.insert_time_sheets.returning[0].from));
      setTimerID(data.insert_time_sheets.returning[0].id);
    },
  });

  const [addTransaction, { data: transRetData }] = useMutation(
    INSERT_TRANSACTION
  );

  const [endClock, { data: endRetData }] = useMutation(END_TIMER, {
    onCompleted(data) {
      setShowTimerLoggedCongrats(true);
    },
  });

  const [updateScheduleAllow, { data: userScheduleFlagData }] = useMutation(SET_SCHEDULE_ALLOW);

  const [updateUser, { loading: mutationLoading }] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: TIMER_INFO,
        variables: {
          id: user.sub,
        },
      },
    ],
  });

  const [updateFirstTimeUser, { loading: firstTimeLoading }] = useMutation(UPDATE_FIRST_TIME_USER);

  if (loading) return <Loading />;
  

  if (error) {
    console.log(error);
  }



  const handleTimeClockStart = () => {
    startClock({
      variables: {
        startTime: formatISO(new Date()),
        userId: user.sub,
      },
    });
  };

  const handleTimeClockEnd = () => {
    const clockout = setDate(new Date(), getDate(clockedInTime))

    setClockedOutTime(clockout);
    setShowTimerCompleteConfirmation(true);
  };

  const handleTimeClockSubmit = (
    fromTime = clockedInTime,
    toTime = clockedOutTime
  ) => {
    let credits = (differenceInMinutes(toTime, fromTime) * (4 / 60)).toFixed(2)
    endClock({
      variables: {
        id: timerID,
        from: formatISO(fromTime),
        to: formatISO(toTime),
        durationMinutes: differenceInMinutes(toTime, fromTime)
      },
    });

    if(creditAmount >= 250) {
      setCredits(0)
      return;
    }

    if (parseFloat(creditAmount) + parseFloat(credits) > 250.00) {
      credits = 250 - creditAmount
    }

    addTransaction({
      variables: {
        amount: credits,
        shiftId: timerID,
        userId: user.sub
      }
    })

    setCredits(credits)

    if(!data.users[0].schedule_allow) {
      console.log('fired');
      updateScheduleAllow({
        variables: {
          id: user.sub
        }
      });
    }
  };

  const handleDoneWithTimer = () => {
    setClockedInTime(false);
    setClockedOutTime(false);
    setTimerID(false);
    setShowTimerCompleteConfirmation(false);
    setShowTimerFix(false);
    setShowTimerLoggedCongrats(false);
  };

  function handleCreditPolicyContiune(values) {
    updateUser({
      variables: {
        id: user.sub,
        photoConsent: values.acceptPhoto
      },
    });
  }
  const {credit, minor, volunteer, faith} = data.cms[0].content[0]


  if (!data.users[0].credit_policy) {
    return (
      <div>
        <div>
        <Formik
          initialValues={{
              acceptTerms: false,
              acceptConsent: false,
              acceptStatementOfFaith: false,
              acceptPhoto: "allow",
          }}
          validationSchema={Yup.object().shape({
              acceptTerms: Yup.bool().oneOf([true], 'Accept Credit Policy is required'),
              acceptConsent: Yup.bool().oneOf([true], 'Accept Consent Form is required'),
              acceptStatementOfFaith: Yup.bool().oneOf([true], 'Accept Statement of Faith is required')
          })}
          onSubmit={values => {
            handleCreditPolicyContiune(values)
          }}
            >
                {({ errors, status, touched, values }) => (
                    <Form>                      
                          <div css={tw`mb-4`}>
                            Please review the following:
                          </div> 
                          <ol>
                            <li css={tw`mb-2 list-decimal`}>
                            Read the {" "}
                          <a css={tw`text-blue-900 underline`} href={credit} target="_blank">
                             Credit Policy
                          </a>
                              <div>
                              <Field css={tw`mr-2`} type="checkbox" name="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                              <label htmlFor="acceptTerms" className="form-check-label">I agree to partner with Beautiful Lives Boutique in responsible stewardship of donated items and the volunteer credit privilege as outlined in this policy.</label>
                              <ErrorMessage name="acceptTerms" component="div" css={tw`text-red-400 font-medium`} />
                           </div>
                        </li>
                        <li css={tw`mb-2 list-decimal`}>
                          Read the {" "}
                          <a css={tw`text-blue-900 underline`} href={volunteer} target="_blank">
                             Volunteer Consent Form
                          </a>
                          <div css={tw`mb-4`}>
                            <Field css={tw`mr-2`} type="checkbox" name="acceptConsent" className={'form-check-input ' + (errors.acceptConsent && touched.acceptConsent ? ' is-invalid' : '')} />
                            <label htmlFor="acceptConsent" className="form-check-label">I agree to the terms of the Volunteer Consent  Form</label>
                            <ErrorMessage name="acceptConsent" component="div" css={tw`text-red-400 font-medium`} />
                        </div>
                        </li>
                        <li css={tw`mb-2 list-decimal`}>
                          Read the {" "}
                          <a css={tw`text-blue-900 underline`} href={faith} target="_blank">
                             Statement of Faith
                          </a>
                          <div css={tw`mb-4`}>
                            <Field css={tw`mr-2`} type="checkbox" name="acceptStatementOfFaith" className={'form-check-input ' + (errors.acceptStatementOfFaith && touched.acceptStatementOfFaith ? ' is-invalid' : '')} />
                            <label htmlFor="acceptStatementOfFaith" className="form-check-label">I agree to respect the faith position of Beautiful Lives Boutique as outlined in the Statement of Faith.</label>
                            <ErrorMessage name="acceptStatementOfFaith" component="div" css={tw`text-red-400 font-medium`} />
                        </div>
                      </li>
                        <li css={tw`mb-2 list-decimal`}>
                          <div>
                            Photo Policy
                          </div>
                          <div role="group" aria-labelledby="my-radio-group">
                          <div css={tw`mb-2`}>
                           Beautiful Lives Boutique may use any photographs or videos taken of me during volunteer activities in any and all publicity and social media:
                          </div>
                          <div css={tw`flex`}>
                            <div css={tw`mr-2`}>
                            <label>
                              <Field css={tw`mr-2`} type="radio" name="acceptPhoto" value="allow" checked={values.acceptPhoto == "allow"}/>
                              I give permission
                            </label>
                            </div>
                            <div>
                            <label>
                              <Field css={tw`mr-2`} type="radio" name="acceptPhoto" value="deny" checked={values.acceptPhoto == "deny"}/>
                              I do not give permission
                            </label>
                            </div>
                          </div>
                        </div>
                        </li>
                      </ol>
                        <div className="form-group">
                          <div css={tw`mb-6 font-bold`}>If under the age of 18 please see volunteer coordinator for parent consent form.</div>
                        <SubmitButton type="submit">Continue</SubmitButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
      </div>
    );
  }

  return (
    <div>
      {showTimerLoggedCongrats ? (
        <div css={tw`text-center pt-24`}>
          <div css={tw`text-3xl font-bold`}>WOOHOO!</div>
          <div css={tw`text-2xl`}>You have successfully recorded a shift.</div>
          <div css={tw`text-2xl`}>You've earned ${credits} in credit. 🥳</div>
          <div>*Credits expire after 90 days</div>
          <div css={tw`flex items-center w-full justify-center`}>
            <Confetti
              ref={() => {
                setActive(true);
              }}
              active={active}
              config={config}
            />
          </div>
          <SubmitButton
            css={tw`mt-48`}
            onClick={(evt) => handleDoneWithTimer()}
          >
            Done
          </SubmitButton>
        </div>
      ) : showTimerFix ? (
        <TimerEdit
          fromDate={clockedInTime}
          toDate={clockedOutTime}
          submitLabel="Submit"
          cancelLabel="Cancel"
          onCancel={setShowTimerFix}
          onSubmit={handleTimeClockSubmit}
        />
      ) : showTimerCompleteConfirmation ? (
        <TimerCompleteConfirmation
          clockedIn={clockedInTime}
          clockedOut={clockedOutTime}
          onTimerSubmit={handleTimeClockSubmit}
          onTimerFix={setShowTimerFix}
        />
      ) : (
        <div>
          <Heading
            headline="Time Clock"
            subHeadline="Are you at the store ready to clock in?"
          />
          <div css={tw`text-xl`}>
            {format(new Date(), "EEEE, LLLL do K:mm aaaa")}
          </div>
          <div>At the {data?.users[0]?.location} location</div>
          <div>
            {isDate(clockedInTime) ? (
              <ClockButton onClick={() => handleTimeClockEnd(true)}>
                Clock out
              </ClockButton>
            ) : (
              <ClockButton onClick={() => setShowNotification(true)}>
                Clock in
              </ClockButton>
            )}
          </div>

          <div>
            <div>
              <div>Clocked in: </div>
              {isDate(clockedInTime) ? (
                <div css={tw`text-xl font-bold`}>
                  {format(clockedInTime, "Pp")}
                </div>
              ) : (
                <div>Hit the button above to clock in. 👆</div>
              )}
            </div>
          </div>
          {showNotification && (
            <NotificationBox
              title="Would you like to start your shift?"
              subTitle="Please dont forget to clock out when you're done. 😉"
              confirmationLabel="Clock in"
              cancelLabel="Cancel"
              onCancel={setShowNotification}
              onConfirmation={handleTimeClockStart}
              icon={clock}
            />
          )}
        </div>
      )}
    </div>
  );
}

function TimerCompleteConfirmation({
  clockedIn,
  clockedOut,
  onTimerSubmit,
  onTimerFix,
}) {

  const[disableButton, setDisableButton] = useState(false)

  const handleTimeConfirm = () => {
    setDisableButton(true)
    onTimerSubmit();
  };

  const handleTimeFix = () => {
    onTimerFix(true);
  };

  return (
    <div>
      <div css={tw`text-2xl text-center mb-8`}>Does this look correct?</div>
      <div css={tw`flex justify-between mb-16 md:block`}>
        <div css={tw`md:mb-1`}>
          <div>Clocked in:</div>
          <div css={tw`text-xl font-bold`}>
            {" "}
            {format(clockedIn, "Pp")}
          </div>
        </div>
        <div>
          <div>Clocked out:</div>
          <div css={tw`text-xl font-bold`}>
            {format(clockedOut, "Pp")}
          </div>
        </div>
      </div>
      <div>
        {
          differenceInHours(clockedOut, clockedIn) < 12 && (
            <SubmitButton
            onClick={(evt) => handleTimeConfirm(true)}
            css={tw`md:mr-4`}
            disabled={disableButton}
          >
            Yes
          </SubmitButton>
          )
        }

        <SubmitButton
          onClick={(evt) => handleTimeFix(true)}
          css={tw`bg-blb-gray-300 text-black`}
        >
          No
        </SubmitButton>
      </div>
    </div>
  );
}

export default Timer;
