import React, { useState, useRef } from "react";
import tw from "twin.macro";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  parseISO,
  parse,
  format,
  eachDayOfInterval,
  eachHourOfInterval,
  addDays,
  isWithinInterval,
  setMinutes,
  isSameDay,
  formatISO
} from "date-fns";
import styled from "@emotion/styled";

import { Heading } from "./Account";
import { SelectButton } from "./Form";
import { ReactComponent as IconSetting } from "./icon-settings.svg";
import { ReactComponent as IconClose } from "./close.svg";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import Loading from "./Loading";
import LocationSettingsForm from "./LocationSettingsForm";

export const SHIFTS_INFO = gql`
  query($date: timestamptz!) {
    locations(order_by: { name: asc }) {
      name
      id
      allow_events
      start_time
      end_time
      no_schedule_dates
    }
    events(
      where: { date_start: { _gte: $date } }
      order_by: { date_start: asc }
    ) {
      id
      date_start
      date_end
      location_id
      user_id
      user {
        first_name
        last_name
        email
        avatar
      }
      location {
        name
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation UpdateTodo(
    $name: String!
    $noScheduleDates: String!
  ) {
    update_locations(
      where: { name: { _eq: $name } }
      _set: {
        no_schedule_dates: $noScheduleDates
      }
    ) {
      affected_rows
    }
  }
`;

const ToolTip = styled.div(
  tw`text-xs rounded absolute text-white shadow-md p-2 whitespace-no-wrap hidden`,
  {
    backgroundColor: "#3c4043",
    bottom: 60,
    left: 8,
    "&::before": {
      content: '" "',
      position: "absolute",
      border: "5px solid #3c4043",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      top: "auto",
      bottom: "-3px",
      borderTopWidth: 4,
      borderBottomWidth: 0,
    },
  }
);

function AdminSchedule() {
  const [locationFilter, setLocationFilter] = useState("Bentonville");
  const [dateFilter, setDateFilter] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [allowSchedule, setAllowSchedule] = useState(true);

  const today = new Date();
  today.setHours(8);

  const { loading, error, data: { locations, events } = {} } = useQuery(
    SHIFTS_INFO,
    {
      variables: {
        date: format(today, "P"),
      },
    }
  );

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [{ query: SHIFTS_INFO, variables: { date:format(today, "P") } }],
  });

  if (loading) return <Loading />;
  if (error) {
    console.log(error);
  }

  const daysToShow = eachDayOfInterval({
    start: today,
    end: addDays(today, 60),
  });

  const locationData = locations.filter(({ name }) => name === locationFilter);

  const hoursToShow = eachHourOfInterval({
    start: parse(locationData[0].start_time, "HH:mm", dateFilter),
    end: parse(locationData[0].end_time, "HH:mm", dateFilter),
  });

  let currNoScheduleDates = locationData[0].no_schedule_dates ? locationData[0].no_schedule_dates.split(",") : []

  const handleLocationFilterClick = (val) => setLocationFilter(val);
  const handleDateFilterClick = (val) => setDateFilter(val);


  const handleAllowScheduleClick = () => {

    const date = formatISO(dateFilter, { representation: 'date' })

    let currNoScheduleDates = locationData[0].no_schedule_dates ? locationData[0].no_schedule_dates.split(",") : []
    console.log(currNoScheduleDates);

    if(currNoScheduleDates.includes(date)) {
      console.log('exsists');
      currNoScheduleDates = currNoScheduleDates.filter(item => item !== date)
    } else {
      currNoScheduleDates = [date, ...currNoScheduleDates]
    }

    console.log(currNoScheduleDates);
    setAllowSchedule(!allowSchedule);
    updateLocation({
      variables: {
        name: locationFilter,
        noScheduleDates: currNoScheduleDates.toString()
      },
    });
  }

  return (
    <div>
      <Heading headline="Schedules" />
      <div>
        <div css={tw`md:flex`}>
          {locations.map(({ name }, idx) => (
            <div key={idx} css={[tw`pr-4`]}>
              <SelectButton
                onClick={(evt) => handleLocationFilterClick(name)}
                active={name == locationFilter}
              >
                {name}
              </SelectButton>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div css={tw`flex`}>
          <div css={tw`ml-auto relative mb-4 z-30 cursor-pointer`}>
            <IconSetting onClick={(evt) => setModalOpen(!modalOpen)} />
            <div
              css={[
                tw`absolute right-0`,
                { width: 320, display: modalOpen ? "block" : "none" },
              ]}
            >
              <div css={tw`bg-white p-4 rounded-lg shadow-2xl`}>
                <LocationSettingsForm
                  location={locationFilter}
                  onSubmitCB={() => setModalOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={tw`flex w-full overflow-x-auto mb-12`}>
        {daysToShow.map((date, idx) => (
          <div
            key={idx}
            css={[
              tw`px-8 py-4 pb-8 rounded-lg mr-4 text-center cursor-pointer`,
              {
                backgroundColor: isSameDay(date, dateFilter)
                  ? "#F8DFDF"
                  : "#F8F8F8",
              },
            ]}
            onClick={(evt) => handleDateFilterClick(date)}
          >
            {format(date, "MMM do EEE")}
          </div>
        ))}
      </div>
      <div>
        <div>
          <div>
          <div css={tw`mb-8`}>
          <div css={tw`mb-2 block`}>Allow scheduling</div>
          <label
            css={[
              tw`relative inline-block`,
              {
                width: 50,
                height: 30,
              },
            ]}
          >
            <input
              css={[
                tw`opacity-0 w-0 h-0`,
                {
                  "&: checked + span": {
                    backgroundColor: "#1890FF",
                    "&:: before": {
                      transform: "translateX(20px)",
                    },
                  },
                },
              ]}
              type="checkbox"
              id="1"
              onChange={(evt) => handleAllowScheduleClick()}
              checked={!currNoScheduleDates.includes(formatISO(dateFilter, { representation: 'date' }))}
            />
            <span
              css={[
                tw`absolute top-0 left-0 right-0 bottom-0 rounded-full transition ease-in duration-500`,
                {
                  backgroundColor: "#C4C4C4",
                  "&:: before": {
                    position: "absolute",
                    content: '" "" "',
                    height: 22,
                    width: 22,
                    left: 4,
                    bottom: 4,
                    backgroundColor: "#fff",
                    transition: ".4s",
                    borderRadius: "50%",
                  },
                },
              ]}
            ></span>
          </label>
        </div>
          </div>
        </div>
        <div>
          {hoursToShow.map((hour, idx) => (
            <div
              key={idx}
              css={[tw`flex p-4 rounded-lg mb-4 items-center bg-blb-gray-300`]}
            >
              <div css={tw`mr-16 w-20`}>{format(hour, "h:mm aaaa")}</div>
              <div css={tw`flex`}>
                {events
                  .filter(({ location }) => location.name === locationFilter)
                  .map(({ date_start, date_end, user }, idx) => {
                    return isWithinInterval(hour, {
                      start: setMinutes(parseISO(date_start), 0),
                      end: parseISO(date_end),
                    }) ? (
                      <div
                        key={idx}
                        css={[
                          tw` w-12 h-12 flex items-center justify-center rounded-full bg-white mx-auto border border-blb-gray-400 text-blb-gray-400 font-semibold mr-2 relative`,
                          {
                            "&:hover": {
                              div: {
                                display: "block",
                              },
                            },
                          },
                        ]}
                      >
                        {user.first_name &&
                          `${user.first_name[0]} ${user.last_name[0]}`}
                        <ToolTip>
                          <div>{`${user.first_name} ${user.last_name}`}</div>{" "}
                          <div>{`${user.email}`}</div>{" "}
                          <div>{`${format(
                            parseISO(date_start),
                            "h:mm aaaa"
                          )} - ${format(parseISO(date_end), "h:mm aaaa")}`}</div>
                        </ToolTip>
                      </div>
                    ) : null;
                  })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminSchedule;
