import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "tailwindcss/dist/base.min.css";
import "./index.css"
import {
  BrowserRouter as Router, Route
} from "react-router-dom";
import Auth0Wrapper from "./auth/Auth0Wrapper";
import Entry from "./Entry";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0Wrapper>
        <Entry />
      </Auth0Wrapper>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
