import React, { useReducer, useState } from "react";
import tw from "twin.macro";
import { useMutation, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, Link } from "react-router-dom";
import styled from "@emotion/styled";
import loadingIcon from "../loading.svg";
import {
  InputContainer,
  SelectButton,
  NonInputLabel,
  Input,
  Label,
  SubmitButton,
  FormContainer,
  SelectButtonWrapper,
} from "../Form";

import { sentenceCase } from "../utils";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "../Account";

const UPDATE_USER = gql`
  mutation UpdateTodo(
    $id: String!
    $birthdate: String!
    $emergencyName: String!
    $emergencyPhoneNumber: String!
    $emergencyRelationship: String!
    $volunteeringFrequency: String!
    $additionalExperience: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        birthdate: $birthdate
        emergency_name: $emergencyName
        emergency_phone_number: $emergencyPhoneNumber
        emergency_relationship: $emergencyRelationship
        volunteering_frequency: $volunteeringFrequency
        additional_experience: $additionalExperience
        is_new: false
      }
    ) {
      affected_rows
    }
  }
`;

const Select = SelectButtonWrapper.withComponent("select");

const Textarea = Input.withComponent("textarea");

function VolunteerPreferences() {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      birthdate: "",
      fullName: "",
      phoneNumber: "",
      relationship: "",
      volunteeringFrequency: "",
      additionalExperience: "",
    }
  );

  const [inputActive, setInputActive] = useState(false);

  const { user } = useAuth0();

  const history = useHistory();

  const [updateUser, { loading: mutationLoading }] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      history.push({
        pathname: "/registration/orientation",
        state: { ...history.location.state },
      });
    },
  });

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleMultipleValueInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;

    if (userInput[name].includes(newValue)) {
      const options = userInput[name];
      options.splice(options.indexOf(newValue), 1);

      setUserInput({ [name]: [...options] });
    } else {
      setUserInput({ [name]: [...userInput[name], newValue] });
    }
  };

  const handleSubmit = (evt) => {
    const {
      birthdate,
      fullName,
      phoneNumber,
      relationship,
      volunteeringFrequency,
      additionalExperience,
    } = userInput;
    updateUser({
      variables: {
        id: user.sub,
        birthdate,
        emergencyName: fullName,
        emergencyPhoneNumber: phoneNumber,
        emergencyRelationship: relationship,
        volunteeringFrequency,
        additionalExperience,
      },
    });
  };

  const handleInputActiveChange = (input) => setInputActive(input);

  const volunteerFrequencyOptions = ["Weekly", "One-time"];

  const inputs = ["fullName", "phoneNumber", "relationship"];
  console.log(history.location.state?.location);

  return (
    <div css={tw`mt-8 md:mt-16`}>
      <Heading
        headline="Volunteer Preferences"
        subHeadline="Set your defaults"
      />
      <div>
        <Link
          to={{
            pathname: "/registration/orientation" ,
            state: { ...history.location.state, skip: true },
          }}
          css={tw`font-semibold text-blb-pink-300 mb-4 underline block`}
        >
          I'll do it later
        </Link>
      </div>
      <FormContainer
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit(evt);
        }}
      >
        <div
          css={tw`hidden md:block w-2/5 bg-blb-pink-200 top-0 right-0 bottom-0 fixed ml-20`}
        ></div>
        <InputContainer>
          <Label htmlFor="birthdate">{"Birthdate"}</Label>
          <Input
            name={"birthdate"}
            type="date"
            value={userInput["birthdate"]}
            onChange={(evt) => handleInputChange(evt)}
            onClick={(evt) => handleInputActiveChange("birthdate")}
            placeholder="yyyy-mm-dd"
          />
          <span css={tw`text-xs pl-2`}>optional</span>
        </InputContainer>
        <div css={tw`pt-2`}>
          <Label css={tw`text-xl`}>Emergency Contact</Label>
          {inputs.map((input, idx) => (
            <InputContainer key={idx}>
              <Label
                htmlFor={input}
                active={
                  Boolean(input === inputActive) || Boolean(userInput[input])
                }
              >
                {sentenceCase(input)}
              </Label>
              <Input
                name={input}
                type="text"
                value={userInput[input]}
                onChange={(evt) => handleInputChange(evt)}
                onClick={(evt) => handleInputActiveChange(input)}
              />
            </InputContainer>
          ))}
        </div>

        <InputContainer>
          <NonInputLabel>
            How often are you (or your group) looking to volunteer?
          </NonInputLabel>
          <div css={tw`flex`}>
            {volunteerFrequencyOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="volunteeringFrequency"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["volunteeringFrequency"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        <InputContainer>
          <NonInputLabel>
            Any special skills, talents, or past experience that you would like
            to contribute to the mission?
          </NonInputLabel>
          <Textarea
            name="additionalExperience"
            value={userInput.additionalExperience}
            onChange={(evt) => handleInputChange(evt)}
            height="100"
          />
        </InputContainer>

        <SubmitButton type="submit" css={tw`flex justify-center`}>
          {mutationLoading ? <img src={loadingIcon} width="40" /> : "Done"}
        </SubmitButton>
      </FormContainer>
    </div>
  );
}

export default VolunteerPreferences;
