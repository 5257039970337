import React, {
  useState,
} from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";

import {
  setHours,
  format,
  setMinutes,
  isAfter,
  isBefore,
} from "date-fns";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { SubmitButton } from "./Form";

export const TimeInput = styled.input({
  width: "100%",
  borderRadius: 16,
  color: "#333",
  padding: ".5rem 1rem",
  fontSize: 24,
  textAlign: "center",
  backgroundColor: "#f1f3f4",
  "&:focus": {
    outline: "none",
  },
});

const ToolTip = styled.div(
  tw`text-xs rounded absolute text-white shadow-md p-2 whitespace-no-wrap`,
  {
    backgroundColor: "#3c4043",
    bottom: 60,
    left: 8,
    "&::before": {
      content: '" "',
      position: "absolute",
      border: "5px solid #3c4043",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      top: "auto",
      bottom: "-3px",
      borderTopWidth: 4,
      borderBottomWidth: 0,
    },
  }
);

function ShiftCreationForm({
  availableStartTime,
  availableEndTime,
  startDate,
  onShiftSubmit,
  onBack,
  onChangeDirection,
}) {
  
  const [time, setTime] = useState({
    from: "",
    to: "",
  });

  const [timeMinutes, setTimeMinutes] = useState({
    from: "",
    to: "",
  });

  const [meridiem, setMeridiem] = useState({
    start: "",
    end: "",
  });

  const [minuteError, setMinuteError] = useState({
    from: false,
    to: false,
  });

  const [hourError, setHourError] = useState({
    from: false,
    to: false,
  });

  const [formError, setFormError] = useState({
    isError: false,
    message: "",
  });

  const today = new Date();

  const handleHourChange = (evt) => {
    let hour = evt.target.value ? parseInt(evt.target.value) : evt.target.value;
    let name = evt.target.name;

    setHourError({ ...hourError, [name]: hour > 12 ? true : false });

    if (meridiem[name] == "p") {
      hour += 12;
    }

    setTime({ ...time, [name]: hour });
  };

  const handleMinuteChange = (evt) => {
    let minute = evt.target.value
      ? parseInt(evt.target.value)
      : evt.target.value;

    let name = evt.target.name;

    setMinuteError({ ...minuteError, [name]: minute > 59 ? true : false });

    setTimeMinutes({ ...timeMinutes, [name]: minute });
  };

  const handleSubmit = (evt) => {
    const startHour = meridiem.start == "p" ? (time.from % 12) + 12 : time.from;
    const endHour = meridiem.end == "p" ? (time.to % 12) + 12 : time.to;

    const startShiftTime = setMinutes(
      setHours(startDate, startHour),
      timeMinutes.from
    );

    const endShiftTime = setMinutes(
      setHours(startDate, endHour),
      timeMinutes.to
    );

    if (
      time.from === "" ||
      timeMinutes.from === "" ||
      time.to === "" ||
      timeMinutes.to === ""
    ) {
      setFormError({
        isError: true,
        message: `Please enter a start time and end time.`,
      });
      return;
    }

    if (isBefore(startShiftTime, today)) {
      setFormError({
        isError: true,
        message: `Oh no! the time machine is currently down so we can't schedule shifts in the past.`,
      });

      return;
    }

    if (isBefore(endShiftTime, startShiftTime)) {
      setFormError({
        isError: true,
        message: `Oh no! we can't schedule this shift because the end time is before the start time.`,
      });

      return;
    }

    if (
      isAfter(startShiftTime, availableEndTime) ||
      isBefore(startShiftTime, availableStartTime) ||
      isAfter(endShiftTime, availableEndTime) ||
      isBefore(endShiftTime, availableStartTime)
    ) {
      setFormError({
        isError: true,
        message: `Oh no! we can't schedule this shift because it's not within the volunteering hours.`,
      });

      return;
    }

    setFormError({ isError: false, message: "" });

    onShiftSubmit(startShiftTime, endShiftTime);
  };

  return (
    <div>
      <div>
        <div
          onClick={() => {
            onBack(0);
            onChangeDirection(1);
          }}
        >
          back
        </div>
        <div>
          <div css={{ fontSize: 26 }}>{format(startDate, "EEEE, LLLL do")}</div>
          <div css={tw`text-xs mb-4 md:text-base`}>
            Volunteer anytime between {format(availableStartTime, "h:mm aaaa")}{" "}
            and {format(availableEndTime, "h:mm aaaa")}
          </div>
        </div>
        <div css={tw`mb-2`}>Set a start time</div>
        <div css={tw`flex mb-4`}>
          <div css={tw`relative flex-1 md:flex-none`}>
            <TimeInput
              type="number"
              name="from"
              placeholder="0"
              value={time.from}
              onChange={(evt) => handleHourChange(evt)}
              max="12"
              min="0"
              css={{
                marginRight: 2,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                marginRight: ".25rem",
                backgroundColor: hourError.from && "#F03434",
              }}
            />
            {hourError.from && <ToolTip>invalid time</ToolTip>}
          </div>
          <div css={tw`relative flex-1 md:flex-none`}>
            <TimeInput
              type="number"
              name="from"
              placeholder="00"
              value={timeMinutes.from}
              onChange={(evt) => handleMinuteChange(evt)}
              max="59"
              min="0"
              css={{
                marginLeft: 2,
                borderRadius: 4,
                backgroundColor: minuteError.from && "#F03434",
              }}
            />
            {minuteError.from && <ToolTip>invalid time</ToolTip>}
          </div>

          <div
            css={
              (tw`flex justify-center ml-2`,
              {
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                fontSize: 24,
                marginLeft: ".25rem",
                backgroundColor: "#f1f3f4",
              })
            }
          >
            <button
              css={{
                padding: ".5rem 1rem",
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                backgroundColor: meridiem.start == "a" && "#1890FF",
                color: meridiem.start == "a" ? "#fff" : "#333",
                "&:focus": {
                  outline: "none",
                },
              }}
              type="button"
              name="start"
              onClick={(evt) => setMeridiem({ ...meridiem, start: "a" })}

            >
              AM
            </button>
            <button
              css={{
                padding: ".5rem 1rem",
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: meridiem.start == "p" && "#1890FF",
                color: meridiem.start == "p" ? "#fff" : "#333",
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={(evt) => setMeridiem({ ...meridiem, start: "p" })}
              name="start"
            >
              PM
            </button>
          </div>
        </div>
      </div>
      <div>
        <div css={tw`mb-2`}>Set a end time</div>
        <div css={tw`flex mb-8`}>
          <div css={tw`relative flex-1 md:flex-none`}>
            <TimeInput
              type="number"
              name="to"
              placeholder="0"
              value={time.to}
              onChange={(evt) => handleHourChange(evt)}
              max="12"
              min="0"
              css={{
                marginRight: 2,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                marginRight: ".25rem",
                backgroundColor: hourError.to && "#F03434",
              }}
            />
          </div>
          <div css={tw`relative flex-1 md:flex-none`}>
            <TimeInput
              type="number"
              name="to"
              placeholder="00"
              value={timeMinutes.to}
              onChange={(evt) => handleMinuteChange(evt)}
              max="59"
              min="0"
              css={{
                marginLeft: 2,
                borderRadius: 4,
                backgroundColor: minuteError.to && "#F03434",
              }}
            />
          </div>
          <div
            css={
              (tw`flex justify-center ml-2`,
              {
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                backgroundColor: "#f1f3f4",
                fontSize: 24,
                marginLeft: ".25rem",
              })
            }
          >
            <button
              type="button"
              name="end"
              onClick={(evt) => setMeridiem({ ...meridiem, end: "a" })}
              css={{
                padding: ".5rem 1rem",
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                backgroundColor: meridiem.end == "a" && "#1890FF",
                color: meridiem.end == "a" ? "#fff" : "#333",
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              AM
            </button>
            <button
              onClick={(evt) => setMeridiem({ ...meridiem, end: "p" })}
              name="end"
              css={{
                padding: ".5rem 1rem",
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: meridiem.end == "p" && "#1890FF",
                color: meridiem.end == "p" ? "#fff" : "#333",
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              PM
            </button>
          </div>
        </div>

        <div>
          {formError.isError && (
            <div css={tw`text-red-500 text-center mb-2`}>
              {formError.message}
            </div>
          )}
          <SubmitButton onClick={() => handleSubmit()} css={tw`mb-1`}>
            Add Shift
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}

export default ShiftCreationForm;
