import React, { useEffect, useState } from "react";
import LogOutButton from "./LogOutButton";
import { useQuery, gql, useMutation } from "@apollo/client";
import { CSVLink } from "react-csv";
import tw from "twin.macro";
import { SubmitButton } from "./Form";
import {ReactComponent as IconDownload} from './icon-export.svg'
import Loading from "./Loading";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { ReactComponent as IconEdit } from "./icon-edit-pencil.svg";
import { DeleteConfirmation } from "./UserShifts";
import { ReactComponent as IconTrash } from "./icon-trash.svg";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  format,
  isValid,
  parseISO,
  differenceInMinutes,
  formatISO,
} from "date-fns";
import TimerEdit from "./TimerEdit";

export const GET_USERS = gql`
  query GetUsers {
    time_sheets(order_by: { from: desc }) {
      id
      from
      to
      duration_minutes
      user {
        id
        first_name
        last_name
        email
        location
      }
    }
  }
`;

const UPDATE_TIMER = gql`
  mutation UpdateTime(
    $id: Int!
    $from: timestamptz
    $to: timestamptz
    $durationMinutes: Int!
  ) {
    update_time_sheets(
      where: { id: { _eq: $id } }
      _set: { to: $to, from: $from, duration_minutes: $durationMinutes }
    ) {
      affected_rows
    }
  }
`;

const DELETE_SHIFT = gql`
  mutation deleteShift($id: Int!) {
    delete_time_sheets(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

const DownloadCSV = SubmitButton.withComponent(CSVLink);


function AdminDashboard() {
  const [showModal, setShowModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [selectedShiftToDelete,setSelectedShiftToDelete] = useState({})

  const [updateTimer] = useMutation(UPDATE_TIMER, {
    onCompleted: () => {
      setShowModal(false);
    },
    refetchQueries: [{ query: GET_USERS }],
  });

  const [deleteShift] = useMutation(DELETE_SHIFT, {
    onCompleted: () => {
      setShowDeletionModal(false)
    },
    refetchQueries: [
      {
        query: GET_USERS,
      },
    ],
  });

  const columns = React.useMemo(() => [
    {
      Header: "First Name",
      accessor: "user.first_name",
      disableFilters: true,
    },
    {
      Header: "Last Name",
      accessor: "user.last_name",
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "user.email",
      disableFilters: true,
    },
    {
      Header: "Location",
      accessor: "user.location",
      disableFilters: true,
    },
    {
      Header: "Date",
      accessor: (row) => format(parseISO(row.from), "P"),
      disableFilters: true,
    },
    {
      Header: "Clocked In",
      accessor: (row) => format(parseISO(row.from), "p"),
      disableFilters: true,
    },
    {
      Header: "Clocked out",
      accessor: (row) =>
        isValid(parseISO(row.to)) ? format(parseISO(row.to), "p") : row.to,
      disableFilters: true,
    },
    {
      Header: "Minutes",
      accessor: "duration_minutes",
      disableFilters: true,
    },
    {
      Header: "Edit",
      id: "id",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleEditClick(e, row)}>
          <IconEdit />
        </button>
      ),
    },
    {
      Header: "Delete",
      id: "it",
      disableFilters: true,
      Cell: ({ row }) => (
        <button onClick={(e) => handleDeleteClick(e, row)}>
          <IconTrash />
        </button>
      ),
    },
  ]);

  const handleEditClick = (e, data) => {
    setSelectedShift({
      ...selectedShift,
      ...data.original,
      from: parseISO(data.original.from),
      to: parseISO(data.original.to),
    });
    setShowModal(true);
  };

  const handleDeleteClick = (e, data) => {
    setSelectedShiftToDelete({
      ...data.original,
    });
    setShowDeletionModal(true);
  };

  const handleDeleteShift = (evt) => {
    console.log(selectedShiftToDelete.id );
    evt.stopPropagation();
    deleteShift({ variables: { id: selectedShiftToDelete.id } });
  };

  const { loading, error, data } = useQuery(GET_USERS);
  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  const d = new Date();

  const handleTimeUpdate = (fromTime, toTime) => {
    updateTimer({
      variables: {
        id: selectedShift.id,
        from: formatISO(fromTime),
        to: formatISO(toTime),
        durationMinutes: differenceInMinutes(toTime, fromTime),
      },
    });
  };

  return (
    <div>
      <h1 css={tw`text-gray-900 text-2xl font-bold mb-2`}>
        User's recorded shifts
      </h1>
      <div css={tw`mb-4`}>
        Number of clocked shifts: {data.time_sheets.length}
      </div>
      <div css={tw`flex`}>
        <CSVLink
          css={tw`block font-bold mb-4 text-center max-w-xs ml-auto flex mr-2`}
          headers={[
            {
              label: 'from', key: 'from',
            },
            {
              label: 'to', key: 'to'
            },
            {
              label: 'duration', key: 'duration_minutes'
            },
            {
              label: 'email', key: 'user.email'
            },
            {
              label: 'location', key: 'user.location'
            }
          ]}
          data={data.time_sheets}
          filename={`blb-volunteer-timeshifts-all-time-${d.getDate()}.${
            d.getMonth() + 1
          }.${d.getFullYear()}.csv`}
        >
          <span><IconDownload css={tw`mr-2`}/></span>
          Export
        </CSVLink>
      </div>
      <div css={tw`overflow-x-scroll`}>
        <UsersTable columns={columns} data={data.time_sheets} />
      </div>
      {showModal && (
        <div
          css={[
            tw`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50`,
            { backgroundColor: "rgba(0,0,0,0.20)" },
          ]}
        >
          <div
            css={[tw`p-6 max-w-md shadow-xl bg-white`, { borderRadius: 15 }]}
          >
            <TimerEdit
              fromDate={selectedShift.from}
              toDate={
                isValid(selectedShift.to)
                  ? selectedShift.to
                  : selectedShift.from
              }
              submitLabel="Update"
              cancelLabel="Cancel"
              onSubmit={handleTimeUpdate}
              onCancel={setShowModal}
            />
          </div>
        </div>
      )}
      {showDeletionModal && (
        <DeleteConfirmation>
          <div css={tw`flex`}>
            <div css={tw`w-1/2 pr-2`}>
              <div
                css={tw`bg-gray-400 py-2 text-center rounded-md`}
                onClick={(evt) => setShowDeletionModal(false)}
              >
                Cancel
              </div>
            </div>
            <div css={tw`w-1/2 pl-2`}>
              <div
                css={tw`bg-blb-blue-500 py-2 text-center rounded-md text-white`}
                onClick={(evt) => handleDeleteShift(evt)}
              >
                Delete shift
              </div>
            </div>
          </div>
        </DeleteConfirmation>
      )}
    </div>
  );
}

function UsersTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      autoResetRowState: false,
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  return (
    <div>
      <table css={tw`text-center w-full`} {...getTableProps()}>
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr
              css={tw`bg-blb-gray-300 rounded-md`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th css={tw`px-4 py-5`} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td css={tw`px-4 py-5`} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

// Create an editable cell renderer
function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
}

export default AdminDashboard;
