export const partnerInfo = [
    {
        title: 'Hub of Hope',
        content: `Offers support services to survivors of human trafficking in Northwest Arkansas. Educates the community including law enforcement officers, medical professionals, schools and more on the nature of the crime of trafficking. Addresses human trafficking at it's origin as opposed to simply treating symptoms of the crimes. 
        hubofhope.org 
        HELP LINE: 1(405) 582-0759`
    },
    {
        title: 'Saving Grace',
        content: `Provides a stable home for girls 18-25 who’ve aged out of foster care and are at risk to be homeless. Support clients through mentoring, life skills training, transportation services and connecting to educational opportunities, etc. to empower generational change. 
        savinggracenwa.org`
    },
    {
        title: 'Go Near Ministry',
        content: `Dedicated to providing education, skills training, and community development projects in various parts of East Africa. 
        Examples: meeting basic tangible and emotional needs of hundreds of orphans while offering safety, caring for and educating children with disabilities, and supporting medical professionals administering services and supplies. Founded by Beautiful Lives Boutique Founder, Melody Taylor. 
        Go-near.org`
    },
    {
        title: 'James 127',
        content: `Empowering widows and orphans in Kenya by providing free sewing and jewelry training to women facing situations of extreme vulnerability. Brings hope that has been lost in the lives of women and children as a result of AIDS, death and poverty. Widowed women learn to create beautiful products leading to a sustainable means to provide for themselves and their families. 
        www.j127foundation.com`
    },
    {
        title: 'Ladies of Grace',
        content: `Supports women incarcerated, (in prison) or recently rejoining the community, through mentorship, Bible study, and life skills training. 
        Partners with other local organizations to ensure women are able to more successfully navigate the transition from prison to community and thus lower recidivism rates. 
        ladiesofgracenwa.org`
    },
    {
        title: 'Global Outfitters',
        content: `Supporting the global ministries and missionaries of New Heights Church. Meets the physical, emotional, mental, occupational and spiritual needs of individuals and families living around the globe. 
        www.global-outfitters.org`
    },
    {
        title: 'Diva and Dude Community Outreach',
        content: `Teaches life and job skills while meeting tangible needs (periodically feeds people out of food trucks, for example). Services foster increased 
        personal value, enhance family dynamics, and formulate community enhancement that generates social 
        responsibility. Their mission is to reconnect the family and create a community of social wellness. 
        divaworld.org`
    },
    {
        title: 'Choices Pregnancy',
        content: `Extends compassionate support and mentoring services to anyone facing an unplanned pregnancy and assists them in making healthy choices. Provides free, confidential pregnancy tests, ultrasounds, pregnancy counseling, parenting classes and STI testing. Offers caring, non-judgemental abortion recovery services and support. 
        choicespregnancynwa.com`
    },
    {
        title: 'Tangible Truth Ministries',
        content: `Offers encouragement and healing through faith-based one on one counselling and mental health services. Trains, teaches and connects women through conferences, meetings and events. Provides a place of refuge at the “Hermitage.” to surround and support women coming out of situations of extreme crisis. 
        tangibletruthministries.com`
    },
    {
        title: 'International Justice Mission',
        content: `A team of lawyers and social workers across the globe rescuing and restoring victims of forced labor and sex trafficking all around the world. They work relentlessly through local justice systems to ensure that traffickers, rapist and other criminals go to jail, so they cannot abuse, exploit, or enslave others. They provide training, mentoring and support to police, judges and other community leaders to slow down and stop the cycle of violence. 
        ijm.org`
    }
]