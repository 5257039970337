import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import tw from "twin.macro";
import styled from "@emotion/styled";
import Confetti from "react-dom-confetti";
import { Heading } from "../Account";
import {
    FormContainer,
  } from "../Form";


/** @jsx jsx */
import { jsx } from "@emotion/core";
import { gql, useQuery } from "@apollo/client";
import Loading from "../Loading";

export const GET_CMS = gql`
  query GetCms($location: String!) {
    cms(where: {_and: [ {name: {_eq: "orientationUrl"}} {location: {name: { _eq: $location}}}]}) {
    id
    content
  }
}
` 

const config = {
  angle: 90,
  spread: 150,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const locationNums = {
  Bentonville: '479-553-7237',
  Fayetteville: '479-301-2256',
  "Siloam Springs": '479-373-2009'
}

function Orientation() {
  const [active, setActive] = useState(false);

  const history = useHistory();
  const { location: { state: { skip, location } = {} } = {} } = history;

  const {loading, error, data} = useQuery(GET_CMS, {
    variables: {
      location: location
    }
  })

  if(loading) return <Loading />

  if(error) {
    console.log(error);
  }

  console.log(data);

  return (
    <div>
    <div css={tw`mt-8 md:mt-16`}>
      <Heading
        headline="Orientation"
        subHeadline=""
      />
        <FormContainer>
            <div
            css={tw`hidden md:block w-2/5 bg-blb-pink-200 top-0 right-0 bottom-0 fixed ml-20`}
            ></div>
            <div css={tw`mb-6`}>If you want to come and volunteer in store your next step is to schedule an orientation with us, or if you have another inquiry email us (at the chosen store they selected.)</div>
            <div css={tw`md:flex`}>
                <div>
                  {
                    data?.cms[0]?.content[0]?.orientationUrl && (
                      <a href={data.cms[0].content[0].orientationUrl} target="_blank" css={tw`w-full block md:w-auto py-3 text-center rounded-lg font-semibold text-lg mb-8 text-white px-6 ml-auto bg-gmb-300 md:mr-4 mb-4`}>Schedule</a>
                    )
                  }
                    
                </div>
                <div>
                <Link
          to={{
            pathname: "/registration/complete",
            state: { ...history.location.state, skip: true },
          }}
          css={tw`w-full block md:w-auto py-3 text-center rounded-lg font-semibold text-lg mb-8 text-gmb-300 px-6 ml-auto bg-white border-gmb-300 border-2`}
        >
          Finish
        </Link>
                </div>
            </div>
        </FormContainer>
    </div>
    </div>
  );
}

export default Orientation;
