import React, { useState } from "react";
import tw from "twin.macro";
import iconPersonalInfo from "./icon-personal-info.svg";
import iconPreferences from "./icon-preferences.svg";
import iconDocuments from "./icon-documents.svg";

import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "@emotion/styled";

/** @jsx jsx */
import { jsx } from "@emotion/core";

const Card = styled(Link)(
  tw`p-6 my-4 rounded-lg block shadow-md no-underline bg-white w-full`
);
export const Title = styled.div(
  tw`text-sm md:text-lg mt-4 mb-2 font-bold text-center md:text-left`
);
export const Heading = ({ headline, subHeadline }) => (
  <div css={tw`mb-8`}>
    <div css={tw`text-3xl font-bold`}>{headline}</div>
    {subHeadline && <div>{subHeadline}</div>}
  </div>
);

const settings = [
  {
    icon: iconPersonalInfo,
    title: "Personal info",
    description: "Provide personal details and how we can reach you.",
    link: "/account-settings/personal-info",
  },
  {
    icon: iconPreferences,
    title: "Volunteer preferences",
    description:
      "Set your time availabilty, interested roles, and other volunteering preferences.",
    link: "/account-settings/volunteer-preferences",
  },
  {
    icon: iconDocuments,
    title: "Policies and Documents",
    description:
      "Review BLB policies and documents.",
    link: "/account-settings/policies",
  },
];
function Account() {
  let { path, url } = useRouteMatch();
  console.log(path);
  return (
    <div>
      <Heading headline="Account" />
      <div css={tw`flex`}>
        {settings.map(({ icon, title, description, link }, idx) => (
          <div key={idx} css={tw`w-1/2 md:w-1/3 px-2 flex min-h-full`}>
            <Card to={link}>
              <div>
                <img src={icon} alt="" css={tw`mx-auto md:m-0`} />
              </div>
              <Title>
                {title}{" "}
                <span css={tw`hidden md:inline-block text-blb-pink-300`}>
                  >
                </span>
              </Title>
              <div css={tw`hidden md:block`}>{description}</div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Account;
