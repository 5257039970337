import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import tw from "twin.macro";
import Loading from "./Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { parseISO, format} from "date-fns"

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "./Account";

export const USER_INFO = gql`
  query($username: String!) {
    users(where: { username: { _eq: $username } }) {
      first_name
      last_name
      email
      created_at
      birthdate
      under_age
      location
      phone_number
      preferred_notification_method
      group_coordination
      group_type
      interested_role
      day_availability
      time_availability
      interested_role
      additional_experience
      schedule_allow
    }
  }
`;

function NewUserScreen(props) {
  const { isAuthenticated } = useAuth0();
  const { loading, error, data } = useQuery(USER_INFO, {
    variables: {
      username: props.match.params.username
    }
  });

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  const user = data.users[0]

  return (
    <div>
      <Heading
        headline="New User"
        subHeadline="This user was created recently"
      />
      <div>
          <ul>
           { Object.keys(user).slice(1).map((key, idx) => (
             <li key={idx} css={tw`mb-4`}><span css={tw`font-semibold mr-2`}>{key}:</span>{`${key == 'created_at' ? format(parseISO(user[key]), 'Pp') : user[key]}`}</li>
           ))}
          </ul>
      </div>
    </div>
  );
}

export default NewUserScreen;
