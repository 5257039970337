import React from "react"
import arrow from "./angle-right-solid.svg"
import tw from "twin.macro";

/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "react-router-dom";

const Breadcrumbs = ({breadcrumbs}) => (
    <div css={tw`flex items-center mb-4`}>
      {breadcrumbs.map((breadcrumb, idx, arr) => {
        
        if (idx < arr.length - 1) {
          return (
            <React.Fragment key={idx}>
              <div css={tw`text-sm capitalize`}><Link to={`/${breadcrumb}`}>{breadcrumb.replace(/\-/, " ")}</Link></div>
              <span>
                <img
                  css={tw`my-1 mx-4`}
                  src={arrow}
                  alt="arrow pointing right"
                  height="10"
                  width="5"
                />
              </span>
            </React.Fragment>
          )
        } else {
          return <div key={idx} css={tw`text-sm capitalize`}>{breadcrumb.replace(/\-/, " ")}</div>
        }
      })}
    </div>
)

export default Breadcrumbs