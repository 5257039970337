import React, { useReducer, useState } from "react";
import tw from "twin.macro";
import { useMutation, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Heading } from "../Account";

import {
  InputContainer,
  SelectButton,
  NonInputLabel,
  Input,
  Label,
  SubmitButton,
  FormContainer,
} from "../Form";

import { sentenceCase } from "../utils";

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $location: String!
    $preferredNotificationMethod: String!
    $underAge: String!,
    $emailConsent: String!
  ) {
    update_users(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        preferred_notification_method: $preferredNotificationMethod
        location: $location
        under_age: $underAge
        is_new: false,
        email_consent: $emailConsent
      }
    ) {
      affected_rows
    }
  }
`;

function PersonalInfo({ onUserRegisterChange }) {
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      preferredNotificationMethod: "",
      location: "",
      underAge:"",
      emailConsent: ""
    }
  );

  const [inputActive, setInputActive] = useState(false);

  const { user } = useAuth0();

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      onUserRegisterChange(true, userInput.location);
    },
  });

  const handleInputChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setUserInput({ [name]: newValue });
  };

  const handleSubmit = (evt) => {
    updateUser({
      variables: {
        id: user.sub,
        firstName: userInput.firstName,
        lastName: userInput.lastName,
        phoneNumber: userInput.phoneNumber,
        preferredNotificationMethod: userInput.preferredNotificationMethod,
        location: userInput.location,
        underAge: userInput.underAge,
        emailConsent: userInput.emailConsent
      },
    });
  };

  const handleInputActiveChange = (input) => setInputActive(input);

  const inputs = ["firstName", "lastName", "phoneNumber"];
  const notificationOptions = ["Text", "Email", "Call"];
  const groupOptions = ["Yes", "No"];
  const locationOptions = ["Bentonville", "Fayetteville", "Siloam Springs"];
  const underAgeOptions = ["Yes", "No"]
  return (
    <div css={tw`mt-8 md:mt-16`}>
      <Heading
        headline="Personal Info"
        subHeadline="Provide personal details and how we can reach you"
      />
      <FormContainer
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit(evt);
        }}
      >
        <div
          css={tw`hidden md:block w-2/5 bg-blb-pink-200 top-0 right-0 bottom-0 fixed ml-20`}
        ></div>
        <div css={tw`md:flex md:flex-wrap`}>
          {inputs.map((input, idx) => (
            <InputContainer key={idx}>
              <Label
                htmlFor={input}
                active={
                  Boolean(input === inputActive) || Boolean(userInput[input])
                }
              >
                {sentenceCase(input)}
              </Label>
              <Input
                name={input}
                type={input == "phoneNumber" ? "number" : "text"}
                value={userInput[input]}
                onChange={(evt) => handleInputChange(evt)}
                onClick={(evt) => handleInputActiveChange(input)}
                required
              />
            </InputContainer>
          ))}
        </div>
        <InputContainer>
          <Label>Are you under the age of 18?</Label>
          <div css={tw`md:flex`}>
            {underAgeOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="underAge"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option === userInput["underAge"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        <InputContainer>
          <Label>Preferred method of contact:</Label>
          <div css={tw`md:flex`}>
            {notificationOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="preferredNotificationMethod"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["preferredNotificationMethod"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>
        <InputContainer>
          <Label>Which location will you be volunteering at?</Label>
          <div css={tw`md:flex`}>
            {locationOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="location"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option == userInput["location"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>

                <InputContainer>
          <Label>
            Do you consent to recieve emails from Beautiful Lives Boutique?
          </Label>
          <div css={tw`md:flex`}>
            {groupOptions.map((option, idx) => (
              <SelectButton
                key={idx}
                type="button"
                name="emailConsent"
                value={option}
                onClick={(evt) => handleInputChange(evt)}
                active={option === userInput["emailConsent"]}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </InputContainer>

        <InputContainer css={tw`md:flex`}>
          <SubmitButton type="submit">
            Continue
          </SubmitButton>
        </InputContainer>
      </FormContainer>
    </div>
  );
}

export default PersonalInfo;
